import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import { Home } from '../home/Home';
import { Nosotros } from '../nosotros/Nosotros';
import { Servicios } from '../servicios/Servicios';
import { Cursos } from '../cursos/Cursos';
import { Noticias } from '../noticias/Noticias';
import { Publicaciones } from '../noticias/Publicaciones';
import { Contacto } from '../contacto/Contacto';
import { RouterPaginas } from '../../paginas/RouterPaginas';

export const Master = () => {

    return(
        <>
            <Header />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/nosotros" component={Nosotros} />
                <Route path="/servicios/:tipo?/:nombre_servicio?" component={Servicios} />
                <Route exact path="/cursos" component={Cursos} />
                <Route exact path="/eventos" component={Noticias} />
                <Route exact path="/blog" component={Publicaciones} />
                <Route exact path="/contacto" component={Contacto} />
                <Route exact path="*" component={RouterPaginas} />
                </Switch>
            <Footer />
            </>
    );
}
