import React from 'react';
import { Row, Col } from 'antd';

export const AvisoPrivacidad = () => {

    return(
        <Row>
            <Col span={24}><br /></Col>
            <Col xs={{span:0, offset:0}} md={{span:20, offset:2}}  >
                <p className="color_naranja" style={{fontSize:'30px', textAlign:'center'}}>AVISO DE PRIVACIDAD SIMPLIFICADO DE MAFR Consultoría Estratégica S.C.</p>
                <p className="color_gris_claro" style={{fontSize:'20px', textAlign:'justify'}}>
                COMERCIALMENTE CONOCIDA COMO “MAF y Asociados”
                <br /><br />

El presente documento constituye el “Aviso de Privacidad” de MAFR Consultoría Estratégica S.C., en lo sucesivo “MAF y Asociados”, con domicilio en Av. Isaac Newton No. 232, Interior PB, Col. Polanco Chapultepec, C. P. 11560, Alcaldía Miguel Hidalgo en la Ciudad de México, mismo que pone a disposición de Usted (el “Titular”), previo a la obtención y tratamiento de sus datos personales.
<br /><br />
Este “Aviso de Privacidad” regula la forma, términos y condiciones conforme a los cuales MAF y Asociados está facultada y autorizada por Usted como Titular para obtener, tratar, transferir y comercializar sus Datos Personales, con las limitaciones del presente Aviso y de conformidad a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
<br /><br />
Al hacer click en “aceptar” a este documento, el Titular manifiesta su Consentimiento expreso para que MAF y Asociados realice el tratamiento de sus datos, de conformidad con este Aviso de Privacidad.
<br /><br />
El Titular manifiesta que en ningún caso proporcionará a MAF y Asociados “datos personales sensibles” conforme a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
<br /><br />
Finalidad: MAF y Asociados utilizará la información obtenida para:
<ul>
    <li>Proporcionar un servicio eficiente y proveer una mejor atención</li>
    <li>Mejorar su experiencia en el uso de los servicios de MAF y Asociados</li>
    <li>Proporcionar y/o comercializar bienes o servicios que puedan resultar relevantes o atractivos para dicho Titular, incluyendo;
        <ul>
        <li>Participación en cursos</li>
        <li>Seminarios</li>
        <li>Eventos empresariales</li>
        <li>Promociones</li>
        <li>Ofertas y publicidad</li>
        </ul>
    </li>
    <li>Cumplimiento con obligaciones comerciales o jurídicas contraídas</li>
    <li>Alta, seguimiento y actualización en los sistemas internos de administración de clientes</li>
    <li>Investigaciones socioeconómicas, procesos de debida diligencia y conocimiento de clientes o distribuidores para el otorgamiento de créditos, concesiones y en general para establecer relaciones comerciales</li>
    <li>Facturación y cobranza</li>
    <li>Atención de quejas y reclamaciones</li>
    <li>Dar y solicitar referencias comerciales a terceros vinculadas con la relación contractual</li>
    <li>Para cumplir fines estadísticos</li>
    <li>Hacer cumplir y/o ejecutar un contrato</li>
    <li>Llevar a cabo inspecciones por parte de las autoridades, auditorías internas, externas y por autoridades</li>
    <li>Solicitarle información sobre el impacto y resultados que han tenido nuestros trabajos</li>
    <li>Prospección comercial</li>
</ul>

<br /><br />
Esta finalidad puede realizarse mediante el tratamiento y uso de los datos directamente a través de MAF y Asociados o de terceros a quienes se transfieran los datos, inclusive en el extranjero.
<br /><br />
Responsable: Para conocer mayor información sobre los términos y condiciones en que serán tratados sus datos personales, rectificarlos o cancelarlos, con los terceros con quienes compartimos su información personal y la forma en que podrá ejercer sus derechos ARCO, puede consultar el aviso de privacidad integral en nuestras oficinas o mediante el correo <a href="mailto:contacto@mafyasociados.com">contacto@mafyasociados.com</a>
                </p>
            </Col>
            <Col xs={{span:20, offset:2}} md={{span:0, offset:0}} >
                <p className="color_naranja" style={{fontSize:'20px', textAlign:'center'}}>AVISO DE PRIVACIDAD SIMPLIFICADO DE MAFR Consultoría Estratégica S.C.</p>
                <p className="color_gris_claro" style={{fontSize:'14px', textAlign:'justify'}}>
                COMERCIALMENTE CONOCIDA COMO “MAF y Asociados”
                <br /><br />

El presente documento constituye el “Aviso de Privacidad” de MAFR Consultoría Estratégica S.C., en lo sucesivo “MAF y Asociados”, con domicilio en Av. Isaac Newton No. 232, Interior PB, Col. Polanco Chapultepec, C. P. 11560, Alcaldía Miguel Hidalgo en la Ciudad de México, mismo que pone a disposición de Usted (el “Titular”), previo a la obtención y tratamiento de sus datos personales.
<br /><br />
Este “Aviso de Privacidad” regula la forma, términos y condiciones conforme a los cuales MAF y Asociados está facultada y autorizada por Usted como Titular para obtener, tratar, transferir y comercializar sus Datos Personales, con las limitaciones del presente Aviso y de conformidad a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
<br /><br />
Al hacer click en “aceptar” a este documento, el Titular manifiesta su Consentimiento expreso para que MAF y Asociados realice el tratamiento de sus datos, de conformidad con este Aviso de Privacidad.
<br /><br />
El Titular manifiesta que en ningún caso proporcionará a MAF y Asociados “datos personales sensibles” conforme a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
<br /><br />
Finalidad: MAF y Asociados utilizará la información obtenida para:
<ul>
    <li>Proporcionar un servicio eficiente y proveer una mejor atención</li>
    <li>Mejorar su experiencia en el uso de los servicios de MAF y Asociados</li>
    <li>Proporcionar y/o comercializar bienes o servicios que puedan resultar relevantes o atractivos para dicho Titular, incluyendo;
        <ul>
        <li>Participación en cursos</li>
        <li>Seminarios</li>
        <li>Eventos empresariales</li>
        <li>Promociones</li>
        <li>Ofertas y publicidad</li>
        </ul>
    </li>
    <li>Cumplimiento con obligaciones comerciales o jurídicas contraídas</li>
    <li>Alta, seguimiento y actualización en los sistemas internos de administración de clientes</li>
    <li>Investigaciones socioeconómicas, procesos de debida diligencia y conocimiento de clientes o distribuidores para el otorgamiento de créditos, concesiones y en general para establecer relaciones comerciales</li>
    <li>Facturación y cobranza</li>
    <li>Atención de quejas y reclamaciones</li>
    <li>Dar y solicitar referencias comerciales a terceros vinculadas con la relación contractual</li>
    <li>Para cumplir fines estadísticos</li>
    <li>Hacer cumplir y/o ejecutar un contrato</li>
    <li>Llevar a cabo inspecciones por parte de las autoridades, auditorías internas, externas y por autoridades</li>
    <li>Solicitarle información sobre el impacto y resultados que han tenido nuestros trabajos</li>
    <li>Prospección comercial</li>
</ul>

<br /><br />
Esta finalidad puede realizarse mediante el tratamiento y uso de los datos directamente a través de MAF y Asociados o de terceros a quienes se transfieran los datos, inclusive en el extranjero.
<br /><br />
Responsable: Para conocer mayor información sobre los términos y condiciones en que serán tratados sus datos personales, rectificarlos o cancelarlos, con los terceros con quienes compartimos su información personal y la forma en que podrá ejercer sus derechos ARCO, puede consultar el aviso de privacidad integral en nuestras oficinas o mediante el correo <a href="mailto:contacto@mafyasociados.com">contacto@mafyasociados.com</a>
                </p>
            </Col>
            <Col span={24}><br /><br /></Col>
        </Row>
    );
}
