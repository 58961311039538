import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Button } from 'antd';

import '../../../assets/css/sitio/home.css';

import src_img_evento_principal from '../../../assets/images/sitio/home/Maf_PromoMasculinidad_Home.jpg';

const { Paragraph } = Typography;

const eventos = [
    { mes: 'Junio', dia: '12', titulo: 'Guía y lineamientos ante la nueva normalidad y lineamientos ante la nueva normalidad', texto: 'Juesves 12 de Junio a las 16:00 h', url: '#' },
    { mes: 'Noviembre', dia: '12', titulo: 'Guía y lineamientos ante la nueva normalidad', texto: 'Juesves 12 de Junio a las 16:00 h', url: '#' },
    { mes: 'Septiembre', dia: '12', titulo: 'Guía y lineamientos ante la nueva normalidad', texto: 'Juesves 12 de Junio a las 16:00 h', url: '#' }
];

export const SeccionEventos = () => {

    const card_eventos = eventos.map(evento => {
        return (
            <Row justify="space-around" align="middle" className="contenedor_info_evento" >
                <Col md={{span:3}} lg={{span:3}}>
                    <Row className="contenedor_fecha_evento">
                        <Col span={24} className="mes_evento">
                            {evento.mes}
                        </Col>
                        <Col span={24} className="dia_evento">
                            {evento.dia}
                        </Col>
                    </Row>
                </Col>
                <Col md={{span:18}} lg={{span:18}}>
                    <Paragraph className="titulo_evento">{evento.titulo}</Paragraph>
                    <Paragraph className="texto_evento">{evento.texto}</Paragraph>
                </Col>
                <Col md={{span:3}} lg={{span:3}} style={{textAlign:'right'}}>
                    <Link to={evento.url} className="link_boton_registro_evento"><Button className="boton_registro_evento">Registro</Button></Link>
                </Col>
            </Row>
        );
    });

    return (
        <Row>
            <Col md={{ span: 24 }} lg={{ span: 16, offset: 0 }}>
                <Paragraph className="titulo_seccion_evento_movil">PRÓXIMOS EVENTOS</Paragraph>
                    <p className="leyenda_eventos">“Nos encontramos trabajando en el desarrollo de próximos eventos. ¡Espéralos”
</p>
                <br />
                { card_eventos }
            </Col>
            <Col xs={{ span: 0 }} lg={{ span: 7, offset: 1 }}>
                <Link to="#"><img src={src_img_evento_principal} className="img_evento_principal" /></Link>
            </Col>
        </Row>
    );
}