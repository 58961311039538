import React from 'react';
import { Row, Col } from 'antd';

import img_src_directorio from '../../../assets/images/paginas/directorio-emergente/maf.jpg';

export const DirectorioEmergente = () => {

    return(
        <Row justify="center" align="middle">
            <Col span={24}>
                <img src={img_src_directorio} style={{width:'100%'}} />
            </Col>
            <Col  span={22}>
            <div data-type="AwesomeTableView" data-filters="" data-viewID="-MBoQUnHDauL2dDMLzmY"></div>
            </Col>
        </Row>
    );
}