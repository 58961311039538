import React, { useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

import { Row, Col, Drawer } from 'antd';

import '../../../assets/css/sitio/header.css';

import icon_facebook from '../../../assets/images/iconos/facebook_BLANCO.png';
import icon_twitter from '../../../assets/images/iconos/twitter_BLANCO.png';
import icon_instagram from '../../../assets/images/iconos/instagra,_BLANCO.png';
import icon_youtube from '../../../assets/images/iconos/youtube_BLANCO.png';
import icon_linkedin from '../../../assets/images/iconos/linkedin_BLANCO.png';
import icon_telefono from '../../../assets/images/iconos/telefono_BLANCO.png';
import icon_mail from '../../../assets/images/iconos/email_BLANCO.png';
import icon_lupa from '../../../assets/images/iconos/lupa_BLANCO.png';

import logo_maf_color from '../../../assets/images/sitio/header/Maf_Logo_COLOR.png';
import logo_maf_blanco from '../../../assets/images/sitio/header/Maf_Logo_BLANCO.png';

const menu = [
    { path: '/', texto_menu: 'Inicio' },
    { path: '/nosotros', texto_menu: 'Nosotros' },
    { path: '/servicios', texto_menu: 'Servicios' },
    { path: '/cursos', texto_menu: 'Cursos' },
    { path: '/eventos', texto_menu: 'Eventos' },
    { path: '/blog', texto_menu: 'Blog' },
    { path: '/contacto', texto_menu: 'Contacto' }
];

export const Header = () => {

    let location = useLocation();
    const [show_menu_movil, setShowMenuMovil] = useState(false);

    let items_menu = menu.map(item => {

        if (item.path === location.pathname) {
            return (
                <Col><Link to={item.path} className="link_item_menu active" >{item.texto_menu}</Link></Col>
            );
        } else {
            return (
                <Col><Link to={item.path} className="link_item_menu" >{item.texto_menu}</Link></Col>
            );
        }

    });

    let items_menu_movil = menu.map(item => {
        return (
            <Col span={24} className="col_link_item_movil" ><Link to={item.path} className="link_item_menu_movil" onClick={() => { setShowMenuMovil(false) }}>{item.texto_menu.toUpperCase()}</Link></Col>
        );
    });

    return (
        <Row>
            <Col xs={0} sm={0} md={24}>
                <Row className="contenedor_contacto" justify="space-around" align="middle">
                    <Col span={14}>
                        <Row>
                            <Col span={12} offset={1} style={{ textAlign: 'right' }}>
                                <a href="mailto:contacto@mafyasociados.com" className="icon_texto_contacto">
                                    <img src={icon_mail} className="icon_img_contacto" alt="mail" /> contacto@mafyasociados.com
                            </a>
                            </Col>
                            <Col span={10} offset={1} style={{ textAlign: 'left' }}>
                                <a href="tel:+525578251014" className="icon_texto_contacto">
                                    <img src={icon_telefono} className="icon_img_contacto" alt="telefono" /> + 52 (55) 7825 1014 al 17
                            </a>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={10} style={{ textAlign: 'center' }}>
                        <a href="https://www.facebook.com/mafyasociados" target="_blank" className="link_imagen_icono_red_social"><img className="img-fluid imagen_icono_red_social" src={icon_facebook} alt="facebook" /></a>
                        <a href="https://twitter.com/MAFyAsociados" target="_blank" className="link_imagen_icono_red_social"><img className="img-fluid imagen_icono_red_social" src={icon_twitter} alt="twitter" /></a>
                        <a href="https://www.instagram.com/mafyasociadosmx/" target="_blank" className="link_imagen_icono_red_social"><img className="img-fluid imagen_icono_red_social" src={icon_instagram} alt="instagram" /></a>
                        <a href="https://www.youtube.com/channel/UC9DSPCkpfpgAQJWbuzLLMAQ/videos" target="_blank" className="link_imagen_icono_red_social"><img className="img-fluid imagen_icono_red_social" src={icon_youtube} alt="youtube" /></a>
                        <a href="https://www.linkedin.com/company/mafyasociados/" target="_blank" className="link_imagen_icono_red_social"><img className="img-fluid imagen_icono_red_social" src={icon_linkedin} alt="linkedin" /></a>
                    </Col>
                </Row>
                <Row className="contenedor_menu" align="middle">
                    <Col span={6} offset={2}>
                        <Link to="/" ><img className="imagen_logo" src={logo_maf_color} alt="logo_maf" /></Link>
                    </Col>
                    <Col span={14}>
                        <Row justify="end">
                            {items_menu}
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={24} md={0}>
                <Row align="middle" className="contendor_header_movil">
                    <Col span={7} offset={1} className="contenedor_icono_hamburguesa">
                        <MenuOutlined className="icons_movil" onClick={() => { setShowMenuMovil(!show_menu_movil) }} />
                    </Col>
                    <Col span={8} className="contenedor_img_logo_blanco">
                        <Link to="/" ><img src={logo_maf_blanco} className="img_logo_blanco" alt="logo_maf" /></Link>
                    </Col>
                    <Col span={7} offset={0} className="contenedor_iconos_movil">
                        <a href="mailto:contacto@mafyasociados.com" className="icons_movil">
                            <img src={icon_mail} className="icon_img_contacto_movil" alt="mail" />
                        </a>
                    &nbsp;&nbsp;&nbsp;
                    <a href="tel:+525578251014" className="icons_movil">
                        <img src={icon_telefono} className="icon_img_contacto_movil" alt="telefono" />
                        </a>
                    </Col>
                </Row>
                
                <Drawer
                    title=""
                    placement='left'
                    closable={true}
                    closeIcon={<CloseOutlined className="icono_cerrar_drawer" />}
                    onClose={ () => { setShowMenuMovil(false) } }
                    visible={show_menu_movil}
                    width='70%'
                    drawerStyle={{backgroundColor:'rgba(252,93,23,0.6)'}}
                    headerStyle={{backgroundColor:'rgba(252,93,23,0.6)'}}
                    bodyStyle={{backgroundColor:'rgba(252,93,23,0.6)'}}
                    >
                    <Row>
                        <Col span={24} className="contenedor_logo_maf_blanco_movil">
                        <img src={logo_maf_blanco} style={{width:'50%'}} alt="logo_maf" />
                        </Col>
                    
                        {items_menu_movil}

                        <Col span={24} className="contenedor_redes_sociales_movil">
                        <a href="https://www.facebook.com/mafyasociados" target="_blank" className="link_imagen_icono_red_social_movil"><img className="img-fluid imagen_icono_red_social_movil" src={icon_facebook} alt="facebook" /></a>
                        <a href="https://twitter.com/MAFyAsociados" target="_blank" className="link_imagen_icono_red_social_movil"><img className="img-fluid imagen_icono_red_social_movil" src={icon_twitter} alt="twitter" /></a>
                        <a href="https://www.instagram.com/mafyasociadosmx/" target="_blank" className="link_imagen_icono_red_social_movil"><img className="img-fluid imagen_icono_red_social_movil" src={icon_instagram} alt="instagram" /></a>
                        <a href="https://www.youtube.com/channel/UC9DSPCkpfpgAQJWbuzLLMAQ/videos" target="_blank" className="link_imagen_icono_red_social_movil"><img className="img-fluid imagen_icono_red_social_movil" src={icon_youtube} alt="youtube" /></a>
                        <a href="https://www.linkedin.com/company/mafyasociados/" target="_blank" className="link_imagen_icono_red_social_movil"><img className="img-fluid imagen_icono_red_social_movil" src={icon_linkedin} alt="linkedin" /></a>
                        
                        <a href="https://www.linkedin.com/company/mafyasociados/" target="_blank" className="link_imagen_icono_red_social_movil"><img className="img-fluid imagen_icono_red_social_movil" src={icon_mail} alt="mail" /> contacto@mafyasociados.com</a> 
                    </Col>

                    </Row>
                </Drawer>
            </Col>
        </Row>
    );
}
