import React from 'react';
import { Row, Col } from 'antd';

import img_src_header from '../../../../assets/images/paginas/finanzas_rosas/Maf_11ImpuestoRosa.jpg'

export const FinanzasRosas = () => {
    return (
        <Row >
            <Col span={24}>
            <img src={img_src_header} style={{ width: '100%' }} />
            </Col>
            <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} style={{ textAlign: 'right' }}>
                
                <br />
                <p className="color_gris_claro texto_tamano" style={{ textAlign: 'justify' }}>
                <strong><span style={{ color:'#eb4b1c'}}>El impuesto rosa y la inclusión financiera de las mujeres en México</span></strong><br/><br/>
                Evelyn Esquivel
                    <br /><br />
                    ¿Sabes qué es el “impuesto rosa/pink tax”? ¿Tiene algún impacto en la economía de las mujeres mexicanas? Aquí te contamos todo lo que debes saber.
<br /><br />
Hacer las compras de la casa, cooperar con el gasto económico de la familia, contratar algún servicio o comprar artículos de primera necesidad, son actividades que todas y todos realizamos. Aunque no lo parezca, o no nos percatemos de ello, tenemos cierta preferencia al comprar unos productos sobre otros, ya sea porque nos gustan los colores, la marca es confiable o simplemente porque nos acostumbramos a ese producto en específico. Lo interesante es que, actualmente, más de la mitad de la población mexicana paga un costo más alto por cada producto que compra o contrata y, seguramente, no lo sabe.
<br/><br/>
	El impuesto rosa se refiere al costo extra que tienen los productos, de distintos ámbitos, que están dirigidos exclusivamente al consumo femenino en comparación con aquellos dirigidos al consumo de los hombres, es decir, un producto de higiene personal, como puede ser un desodorante, cuesta alrededor del 10% más para el sexo femenino que para el masculino. Un estudio realizado por el Departamento de Asuntos del Consumidor de la Ciudad de Nueva York, Estados Unidos<sup>1</sup> , arrojó que las mujeres pagan un costo mayor por un producto de igual o muy similar calidad y función a uno dirigido para los hombres. 
    <br/><br/>
Ante esto, México no es la excepción, y lo que llama la atención es que múltiples empresas globales han advertido que el costo no se enfoca en una discriminación de precios basadas en el género, sin embargo, atienden a una cuestión de mercadotecnia en donde crear un empaque visualmente atractivo o publicidad que posicione sus productos en altos niveles de consumo requiere una inversión monetaria más grande. La recomendación más destacada para lograr evitar el pago del impuesto rosa (o al menos aminorarlo), consiste en buscar productos neutros que no se dirijan a un género en específico o bien, en comparar los precios de aquellos que son dirigidos a los hombres que satisfagan las necesidades de las consumidoras sin pagar el costo extra por un producto similar.
<br/><br/>
En razón de lo anterior, podríamos decir que las técnicas de mercadotecnia se basan en desarrollar estrategias que atrapen al mercado femenino, quienes por décadas se ha creído que son las consumidoras más grandes que existen, sin embargo, eso también es un estereotipo de género. Lo que sí es real, es que las mujeres ocupan un porcentaje importante en la toma de decisiones respecto al gasto económico en comparación del resto de la población nacional.
<br/><br/>
<strong>Hablemos de números</strong><br/><br/>

De acuerdo al último censo poblacional realizado por el INEGI (2015), las mujeres representan el 51.4% de la población total mexicana. Aunado a lo anterior, según datos de la Encuesta Nacional de Inclusión Financiera (ENIF, 2018)<sup>2</sup> , el 19.9% de las mujeres a nivel nacional, son jefas de familia y en ellas recae la decisión de los gastos, no sin antes recordar que el poder adquisitivo de una mujer mexicana es mucho menor al de un hombre y esto se debe a la llamada brecha salarial.
<br/><br/>
La brecha salarial de género es la desigualdad en la remuneración económica laboral que existe entre una mujer y un hombre, es decir, en México, los hombres ganan en promedio 54.5%<sup>3</sup>  más que las mujeres, además de ocupar el 2.4%<sup>4</sup>  de altos puestos de mando o gerencia en relación con el 0.9% ocupado por las mujeres. Las grandes preguntas a todo lo anterior son: ¿por qué las mujeres pagan más por un producto de primera necesidad a diferencia de los hombres, si son quienes obtienen menos ingresos? ¿será posible que las empresas se aprovechen de los estereotipos de género existentes para atraer, principalmente, al mercado femenino? Lo cierto es que, este tipo de prácticas, en tanto no suceda una modificación a nivel legislativo, seguirán llevándose a cabo y perpetrando la desigualdad de oportunidades y de género existente en nuestro país.
<br/><br/>
Por otra parte, es importante destacar el nivel de participación e inclusión que las mujeres perciben de sí mismas en el sistema financiero mexicano, pues se reflejan diferencias en distintos rubros, por ejemplo, el acceso a los productos financieros bancarios, ya que de acuerdo a los datos arrojados por la ENIF 2018, solo el 20% de las mujeres dijo contar o haber tenido alguna vez una cuenta o tarjeta bancaria, incluso aquellas que solamente son de ahorro.
<br/><br/>
Si pasamos al sector de créditos, las estadísticas son aún más alarmantes, pues el 73.5% de las mujeres, manifestó no contar con un crédito por parte de alguna institución financiera o tienda departamental. Por cuanto hace a los créditos de vivienda, la situación no mejora en nada, pues solamente el 3.7% dijo gozar de un crédito de vivienda a comparación de un 7.3% de los hombres que sí cuentan con uno, estimando, que la razón principal por la cual las mujeres no pueden acceder a créditos con instituciones financieras o de vivienda, es porque no cumplen los requisitos necesarios o, más específicamente, porque no cuentan con las percepciones económicas requeridas para la obtención del crédito.
<br/><br/>
Respecto al ámbito de los seguros, solamente el 23.1% de las mujeres cuenta con algún tipo de seguro, ya sea de auto, casa, gastos médicos o de vida, sin embargo, las mujeres pagan primas de seguro con costos más elevados que los de los hombres, en virtud de que se estima que la esperanza de vida de una mujer es mayor a la de un hombre.
<br/><br/>
Como se observa, aun cuando las mujeres han ido ganando terreno en la lucha por sus derechos, existe mucho por hacer para eliminar las diferencias que proliferan la discriminación y, lograr por fin, la igualdad de género y de oportunidades que tanto necesita nuestro país. La acción más importante y de mayor urgencia, es la creación de estrategias, mecanismos o planes financieros que otorguen facilidades de acceso a las mujeres a sectores como: ahorros para su fondo de retiro, acceso a créditos bancarios y/o de vivienda, planes de seguros a la medida y otorgamiento de créditos para el emprendimiento de negocios que deriven en el empoderamiento económico de las mujeres en el país.
<br/><br/>
Una pieza clave para lograr armar el rompecabezas de la inclusión financiera para las mujeres en México, comienza con una educación financiera a temprana edad que coadyuve con el óptimo aprovechamiento de los productos financieros disponibles en el mercado, no sin antes mencionar la necesidad tan urgente que existe de que estas instituciones y los tres órdenes de gobierno trabajen conjuntamente para otorgar mejores condiciones y productos que tracen el camino al empoderamiento económico ideal para otorgar, sobre todo a las mujeres, igualdad de oportunidades.

<br /><br />
                    <hr style={{ marginLeft: '20px', marginRight: '20px' }} />

                    <br /><br />
                    <small>
                        <sup><strong>1</strong></sup> From Cradle to Cane: The Cost of Being a Female Consumer. NYC Consumer Affairs. <a href="https://www1.nyc.gov/assets/dca/downloads/pdf/partners/Study-of-Gender-Pricing-in-NYC.pdf" target="_blank" className="link_sin_decoracion">https://www1.nyc.gov/assets/dca/downloads/pdf/partners/Study-of-Gender-Pricing-in-NYC.pdf</a> <br />
                        <sup><strong>2</strong></sup>   Encuesta Nacional de Inclusión Financiera. Instituto Nacional de Estadística y Geografía, Comisión Nacional Bancaria y de Valores, 2018 <a href="https://www.gob.mx/cms/uploads/attachment/file/414832/Libro_Tabulados_ENIF2018.pdf" target="_blank" className="link_sin_decoracion">https://www.gob.mx/cms/uploads/attachment/file/414832/Libro_Tabulados_ENIF2018.pdf</a>
                        <br />
                        <sup><strong>3</strong></sup><a href="Estrategia de Empleo de la OCDE, 2018. https://www.oecd.org/mexico/jobs-strategy-MEXICO-ES.pdf" target="_blank" className="link_sin_decoracion">Estrategia de Empleo de la OCDE, 2018. https://www.oecd.org/mexico/jobs-strategy-MEXICO-ES.pdf</a>
                        <br/>
                        <sup><strong>4</strong></sup>Encuesta Nacional de Inclusión Financiera. Instituto Nacional de Estadística y Geografía, Comisión Nacional Bancaria y de Valores, 2018.
                    </small>
                </p>

            </Col>
        </Row>
    );
}