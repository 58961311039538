import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography } from 'antd';
import Slider from "react-slick";

import src_img_conoce_mas from '../../../assets/images/sitio/home/00Boton_CONOCEMAS.png';
import src_img_logo_sol from '../../../assets/images/sitio/home/Logo-EL-SOL-DE-MEXICO.png';
import src_pdf_recomendaciones_transportistas from '../../../assets/files/recomendaciones_transportistas/maf_recomendaciones_transportistas.pdf';

const { Paragraph } = Typography;


export const CarouselBanners = () => {

    var settings = {
        pauseOnHover: true,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        appendDots: dots => (
            <div className="div_dots">
                <ul> {dots} </ul>
            </div>
        ),
        customPaging: i => (

            <div className="li_dots">

            </div>
        )
    };

    return (
        <div>
            <Slider {...settings}>
                <div>
                    <Row align="middle" className="banner contenedor_banner_servicios">
                        <Col xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }} lg={{ span: 12, offset: 2 }}
                            style={{ textAlign: 'left' }}>
                            <Paragraph className="texto_banner" style={{ color: 'white' }}>
                                <strong>SOMOS LA MEJOR SOLUCIÓN</strong><br />
                                CORPORATIVA Y JURÍDICA<br />
                                PARA SU EMPRESA
                            </Paragraph>
                            <p><Link to="/servicios" ><img src={src_img_conoce_mas} style={{ width: '300px', display: 'inline' }} alt="ir a servicios" /></Link></p>
                        </Col>
                        <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}
                            style={{ textAlign: 'center' }}>
                            <Paragraph className="texto_banner_movil" style={{ color: 'white', textAlign: 'center' }} >
                                SOMOS LA MEJOR SOLUCIÓN
                                CORPORATIVA Y JURÍDICA
                                PARA SU EMPRESA
                                <br /><br />
                                <Link to="/servicios" ><img src={src_img_conoce_mas} style={{ width: '300px', display: 'inline' }} alt="ir a servicios" /></Link>
                            </Paragraph>
                        </Col>
                    </Row>
                </div>

                <div>
                    <Row align="middle" className="banner contenedor_banner_directorio">
                        <Col xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }} lg={{ span: 12, offset: 2 }}
                            style={{ textAlign: 'left' }}>
                            <Paragraph className="texto_banner" style={{ color: 'white' }}>
                                <strong>¡NO ESTAS SOLA!</strong><br />
                                DESCUBRE NUESTRO 
                                <br />
                                DIRECTORIO EMERGENTE
                            </Paragraph>
                            <p><Link to="/directorioemergente" ><img src={src_img_conoce_mas} style={{ width: '300px', display: 'inline' }} alt="ir a directorio emergente" /></Link></p>
                        </Col>
                        <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}
                            style={{ textAlign: 'center' }}>
                            <Paragraph className="texto_banner_movil" style={{ color: 'white', textAlign: 'center' }} >
                                ¡NO ESTAS SOLA!<br />
                                DESCUBRE NUESTRO DIRECTORIO EMERGENTE<br /> <br />
                                <Link to="/directorioemergente" ><img src={src_img_conoce_mas} style={{ width: '300px', display: 'inline' }} alt="ir a directorio emergente" /></Link>
                            </Paragraph>
                        </Col>
                    </Row>
                </div>



                <div>
                    <Row align="middle" className="banner contenedor_banner_ciberseguridad">
                        <Col xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }} lg={{ span: 12, offset: 2 }}
                            style={{ textAlign: 'left' }}>
                            <Paragraph className="texto_banner" style={{ color: 'white' }}>
                                <strong>CIBERSEGURIDAD</strong><br />
                                COMO ELEMENTO ESENCIAL<br />
                                PARA EL ÉXITO DE UNA EMPRESA
                            </Paragraph>
                            <p><Link to="/la-ciberseguridad-como-elemento-esencial-para-el-exito-de-una-empresa" ><img src={src_img_conoce_mas} style={{ width: '300px', display: 'inline' }} alt="ir a ciberseguridad" /></Link></p>
                        </Col>
                        <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}
                            style={{ textAlign: 'center' }}>
                            <Paragraph className="texto_banner_movil" style={{ color: 'white', textAlign: 'center' }} >
                                CIBERSEGURIDAD<br />
                                COMO ELEMNTO ESENCIAL PARA EL ÉXITO DE UNA EMPRESA
                                <br /><br />
                                <Link to="/la-ciberseguridad-como-elemento-esencial-para-el-exito-de-una-empresa" ><img src={src_img_conoce_mas} style={{ width: '300px', display: 'inline' }} alt="ir a ciberseguridad" /></Link>
                            </Paragraph>
                        </Col>
                    </Row>
                </div>

                <div>
                    <Row align="middle" className="banner contenedor_banner_covid">
                        <Col xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }} lg={{ span: 12, offset: 2 }}
                            style={{ textAlign: 'left' }}>
                            <Paragraph className="texto_banner" style={{ color: 'white' }}>
                                <strong>EMERGENCIA SANITARIA POR COVID 19</strong><br />
                                REFLEXIONES DESDE EL DERECHO <br />
                                NURIA GONZÁLEZ MARTÍN - COORDINADORA
                            </Paragraph>
                            <p><a href="https://www.juridicas.unam.mx/publicaciones/detalle-publicacion/152" target="_blank"><img src={src_img_conoce_mas} style={{ width: '300px', display: 'inline' }} alt="ir a emergencia sanitaria covid 19" /></a></p>
                        </Col>
                        <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}
                            style={{ textAlign: 'center' }}>
                            <Paragraph className="texto_banner_movil" style={{ color: 'white', textAlign: 'center' }} >
                                EMERGENCIA SANITARIA POR COVID 19<br />
                                REFLEXIONES DESDE EL DERECHO | NURIA GONZÁLEZ - COORDINADORA
                                <br /><br />
                                <a href="https://www.juridicas.unam.mx/publicaciones/detalle-publicacion/152" target="_blank"><img src={src_img_conoce_mas} style={{ width: '300px', display: 'inline' }} alt="ir a emergencia sanitaria covid 19" /></a>
                            </Paragraph>
                        </Col>
                    </Row>
                </div>

                <div>
                    <Row align="middle" className="banner contenedor_banner_equipo">
                        <Col xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }} lg={{ span: 12, offset: 2 }}
                            style={{ textAlign: 'left' }}>
                            <Paragraph className="texto_banner" style={{ color: 'white' }}>
                                <strong>CONOCE</strong><br />
                                A NUESTRO EQUIPO <br />
                                DE ASESORES ESPECIALIZADOS
                            </Paragraph>
                            <p><Link to="/nosotros" ><img src={src_img_conoce_mas} style={{ width: '300px', display: 'inline' }} alt="ir a nosotros" /></Link></p>
                        </Col>
                        <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}
                            style={{ textAlign: 'center' }}>
                            <Paragraph className="texto_banner_movil" style={{ color: 'white', textAlign: 'center' }} >
                                CONOCE A NUESTRO EQUIPO DE ASESORES ESPECIALIZADOS
                                
                                <br /><br />
                                <Link to="/nosotros" ><img src={src_img_conoce_mas} style={{ width: '300px', display: 'inline' }} alt="ir a nosotros" /></Link>
                            </Paragraph>
                        </Col>
                    </Row>
                </div>

                <div>
                    <Row align="middle" className="banner contenedor_banner_publicaciones">
                        <Col xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }} lg={{ span: 12, offset: 2 }}
                            style={{ textAlign: 'left' }}>
                            <Paragraph className="texto_banner" style={{ color: 'white' }}>
                                <strong>ANTES DE LA SANCIÓN</strong><br />
                                ¿DÓNDE ESTA LA PREVENCIÓN? <br />
                                30 DE JULIO - DRA. MA. DE LOS ÁNGELES FROMOW
                            </Paragraph>
                            <p><Link to="/publicaciones" ><img src={src_img_conoce_mas} style={{ width: '300px', display: 'inline' }} alt="ir a publicaciones" /></Link>
                            <img src={src_img_logo_sol} style={{ width: '150px', display: 'inline', paddingLeft: '15px' }} alt="ir a publicaciones" /></p>
                        </Col>
                        <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}
                            style={{ textAlign: 'center' }}>
                            <Paragraph className="texto_banner_movil" style={{ color: 'white', textAlign: 'center' }} >
                            ANTES DE LA SANCIÓN ¿DÓNDE ESTA LA PREVENCIÓN?
                                
                                <br /><br />
                                <Link to="/publicaciones" ><img src={src_img_conoce_mas} style={{ width: '300px', display: 'inline' }} alt="ir a publicaciones" />
                                </Link>

                            </Paragraph>
                        </Col>
                    </Row>
                </div>
            </Slider>
        </div>
    );

}