import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Button, Typography } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import servicios_privados from './servicios_privados.json';
import servicios_publicos from './servicios_publicos.json';
import servicios_profesionalizacion from './servicios_profesionalizacion.json';

import img_src_privada_asesoria from '../../../assets/images/sitio/servicios/privado/Maf_AsesoriaJuridica.jpg';
import img_src_privada_asuntos from '../../../assets/images/sitio/servicios/privado/Maf_AsuntosCorporativos.jpg';
import img_src_privada_consultoria from '../../../assets/images/sitio/servicios/privado/Maf_ConsultoriaEmpresarial.jpg';
import img_src_privada_mercadotecnia from '../../../assets/images/sitio/servicios/privado/Maf_Mercadotecnia.jpg';
import img_src_privada_servicios_digitales from '../../../assets/images/sitio/servicios/privado/Maf_ServiciosDigitales.jpg';

import img_src_publico_asesoria from '../../../assets/images/sitio/servicios/publico/Maf_AsesoriaPolitica.jpg';
import img_src_publico_auditoria from '../../../assets/images/sitio/servicios/publico/Maf_AuditoriasYServiciosDeCalidad.jpg';
import img_src_publico_estrategia from '../../../assets/images/sitio/servicios/publico/Maf_EstrategiaDeSeguridadYJusticia.jpg';
import img_src_publico_mercadotecnia from '../../../assets/images/sitio/servicios/publico/Maf_MercadotecniaPublico.jpg';
import img_src_publico_otros_servicios from '../../../assets/images/sitio/servicios/publico/Maf_OtrosServicios.jpg';
import img_src_publico_planeacion from '../../../assets/images/sitio/servicios/publico/Maf_PlaneacionYReintegracionDeProcesos.jpg';
import img_src_publico_servicios_digitales from '../../../assets/images/sitio/servicios/publico/Maf_ServiciosDigitalesPublico.jpg';

import img_src_profesionalizacion_calidad from '../../../assets/images/sitio/servicios/profesionalizacion/Maf_Calidad.jpg';
import img_src_profesionalizacion_justicia from '../../../assets/images/sitio/servicios/profesionalizacion/Maf_JusticiaSocial.jpg';
import img_src_profesionalizacion_liderazgo from '../../../assets/images/sitio/servicios/profesionalizacion/Maf_Liderazgo.jpg';
import img_src_profesionalizacion_planeacion from '../../../assets/images/sitio/servicios/profesionalizacion/Maf_Planeacion.jpg';

const img_src_privadas = [img_src_privada_asesoria,img_src_privada_asuntos,img_src_privada_consultoria,
    img_src_privada_mercadotecnia,img_src_privada_servicios_digitales];

const img_src_publicas = [img_src_publico_estrategia, img_src_publico_asesoria, img_src_publico_otros_servicios,
    img_src_publico_auditoria, img_src_publico_mercadotecnia, img_src_publico_servicios_digitales, img_src_publico_planeacion]

const img_src_profesionalizacion = [img_src_profesionalizacion_justicia, img_src_profesionalizacion_liderazgo,
    img_src_profesionalizacion_calidad, img_src_profesionalizacion_planeacion];

let show_servicio = [];
let img_src = [];

const { Text } = Typography;

export const MostrarServicios = (props) => {


    const settingsServicios = {
        className: "center",
            infinite: true,
            pauseOnHover:true,
            autoplay:true,
            arrows:true,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true
        };
    
        const { nombre_servicio } = useParams();
    const { tipo_servicio, carousel } = props;
    let cambiarCarousel;

    const myRef = useRef(null);

    const carousel_metodos = {
        next() {
            cambiarCarousel.slickNext();
          },
          previous() {
            cambiarCarousel.slickPrev();
          }
    }

    switch (tipo_servicio) {
        case 'privado':
            show_servicio = servicios_privados;
            img_src = img_src_privadas;
            break;
        case 'publico':
            show_servicio = servicios_publicos;
            img_src = img_src_publicas;
            break;
        case 'profesionalizacion':
            show_servicio = servicios_profesionalizacion;
            img_src = img_src_profesionalizacion;
            break;
        default:
            show_servicio = servicios_privados;
            img_src = img_src_privadas;
            break;
    }
    let cambio_posicion = true;
    let divs_servicios = show_servicio.map(servicio => {

        let parrafos = servicio.lista.map(parrafo => {
            return (
                <>
                    <span>{parrafo.parrafo}</span>
                    <br />
                </>
            );
        });

        if(cambio_posicion){
            if(!carousel){
                cambio_posicion = !cambio_posicion;
            }
            
            return (
                <div ref={servicio.id_url === nombre_servicio ? myRef : null}>
                    <Row className="contenedor_servicio_blanco">
                        <Col xs={{span:24, offset:0}} md={{span:10, offset:2}}>
                            <img src={img_src[servicio.img_index_src]}  className="img_servicio" alt={servicio.titulo} />
                        </Col>
                        <Col xs={{span:24, offset:0}} md={{span:8, offset:1}}>
                            <br />
                            <Text className="titulo_servicio" >{servicio.titulo.toUpperCase()}
                            <hr className="linea_servicio" />
                            </Text>
                            
                            <Text className="parrafos_servicio">
                                {parrafos}
                            </Text>
                            <br /><br />
                            <a href="mailto:contacto@mafyasociados.com"><Button className="boton_contactanos_servicio">¡CONTÁCTANOS!</Button></a>
                        </Col>
                    </Row>
                </div>
            );
        }else{
            cambio_posicion = !cambio_posicion;
            return (

                <div ref={servicio.id_url === nombre_servicio ? myRef : null}>
                    <Row className="contenedor_servicio_gris">
                    <Col xs={{span:24, offset:0}} md={{span:8, offset:2}}>
                            <br />
                            <Text className="titulo_servicio" >{servicio.titulo.toUpperCase()}
                            <hr className="linea_servicio" />
                            </Text>
                            
                            <Text className="parrafos_servicio">
                                {parrafos}
                            </Text>
                            <br /><br />
                            <a href="mailto:contacto@mafyasociados.com"><Button className="boton_contactanos_servicio">¡CONTÁCTANOS!</Button></a>
                        </Col>
                        <Col xs={{span:24, offset:0}} md={{span:10, offset:1}}>
                            <img src={img_src[servicio.img_index_src]}  className="img_servicio" alt={servicio.titulo} />
                        </Col>
                        
                    </Row>
                </div>
            );
        }

        
    });


    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    useEffect(() => {
        
            sleep(100).then(() => {

                console.log(myRef);
                if(typeof myRef.current !== 'undefined' && myRef.current !== null){
                myRef.current.scrollIntoView({
                    behavior: "smooth",
                    block:   "start",
                });
            }
            })
        
        
    },[nombre_servicio]);

    return (
        <Row >
            {carousel ?
                (
                    <>
                        <Col xs={{ span: 4, offset: 0 }} style={{ textAlign: 'right' }}>
                            <LeftOutlined className="flechas_cambiar_carousel_servicios" onClick={() => { carousel_metodos.previous() }} />
                        </Col>
                        <Col xs={{ span: 16, offset: 0 }} md={{ span: 20, offset: 0 }}>
                        <Slider {...settingsServicios} ref={c => (cambiarCarousel = c)} >
                                {divs_servicios}
                            </Slider>
                        </Col>
                        <Col xs={{ span: 4, offset: 0 }} style={{ textAlign: 'left' }}>
                            <RightOutlined className="flechas_cambiar_carousel_servicios" onClick={() => { carousel_metodos.next() }} />
                        </Col>
                    </>
                )
                :
                (
                    <Col span={24}>
                    { divs_servicios }
                    </Col>
                )
            }
        </Row>
    );

}
