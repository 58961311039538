import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

import categoria_responsabilidad_social from './ResponsabilidadSocial.json';
import categoria_tips_empresariales from './TipsEmpresariales.json';
import categoria_violencia_genero from './ViolenciaGenero.json';
import categoria_webinars from './Webinars.json';
import categoria_publicaciones from './Publicaciones.json';
import categoria_articulos from './Articulos.json';
import categoria_todos from './Todos.json';


const img_src_responsabilidad = [
    require('../../../assets/images/sitio/noticias/Maf_911.jpg')
];

const img_src_tips_empresariales = [
    require('../../../assets/images/sitio/noticias/Maf_EmergenciaSanitaria_not.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_RecomendacionesTransportistas_not.jpg')
];

const img_src_violencia_genero = [
    require('../../../assets/images/sitio/noticias/Maf_911.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_Masculinidad.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_Estereotipos_not.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_PrivilegiosMaculinos.jpg')
];

const img_src_webinars = [
    require('../../../assets/images/sitio/noticias/Maf_Masculinidad.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_HomeOffice.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_CiberSegNoticias.jpg')
];

const img_src_publicaciones = [
    require('../../../assets/images/sitio/noticias_doctora/Maf_17Marzo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_09Abril.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_23Abril.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_07Mayo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_21Mayo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_04Junio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_18Junio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_02Julio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_16Julio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_30Julio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_13Agosto.jpg')
];
const img_src_articulos = [
    require('../../../assets/images/sitio/noticias/header_conapred_articuli.png'),
    require('../../../assets/images/sitio/noticias/Maf_11ImpuestoRosa.jpg')
];

const img_src_todos = [
    require('../../../assets/images/sitio/noticias/Maf_911.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_EmergenciaSanitaria_not.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_RecomendacionesTransportistas_not.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_Masculinidad.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_Estereotipos_not.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_17Marzo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_09Abril.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_23Abril.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_07Mayo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_21Mayo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_04Junio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_18Junio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_02Julio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_16Julio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_30Julio.jpg'),
    require('../../../assets/images/sitio/noticias/header_conapred_articuli.png'),
    require('../../../assets/images/sitio/noticias/Maf_11ImpuestoRosa.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_13Agosto.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_PrivilegiosMaculinos.jpg')
];




let categoria_seleccionada, img_src_categoria_seleccionada;

export const CardsCategoria = (props) => {

    const { categoria } = props;

    switch (categoria) {
        case 'responsabilidad_social': 
        categoria_seleccionada = categoria_responsabilidad_social; 
        img_src_categoria_seleccionada = img_src_responsabilidad;
        break;
        case 'tips_empresariales': 
        categoria_seleccionada = categoria_tips_empresariales; 
        img_src_categoria_seleccionada = img_src_tips_empresariales;
        break;
        case 'violencia_de_genero': 
        categoria_seleccionada = categoria_violencia_genero; 
        img_src_categoria_seleccionada = img_src_violencia_genero;
        break;
        case 'webinars': 
        categoria_seleccionada = categoria_webinars; 
        img_src_categoria_seleccionada = img_src_webinars;
        break;
        case 'publicaciones': 
        categoria_seleccionada = categoria_publicaciones; 
        img_src_categoria_seleccionada = img_src_publicaciones;
        break;
        case 'articulos': 
        categoria_seleccionada = categoria_articulos; 
        img_src_categoria_seleccionada = img_src_articulos;
        break;
        case 'todos': 
        categoria_seleccionada = categoria_todos; 
        img_src_categoria_seleccionada = img_src_todos;
        break;
        default: categoria_seleccionada = categoria_todos; img_src_categoria_seleccionada = img_src_todos; break;
    }


    let cards_categoria = categoria_seleccionada.map(categoria => {

        return(
            <Col xs={{span:24, offset:0}} sm={{span:20, offset:2}} md={{span:12, offset:0}} className="contenedor_item_categoria">
                <a target="_blank" href={categoria.url} className="link_item_categoria">
                    
                    <img src={img_src_categoria_seleccionada[categoria.index_img]} className="img_item_categoria" />
                    </a>
                    <br />
                    <p class="titulo_item_categoria">
                        { categoria.titulo}
                    </p>
                    <hr class="linea_citem_ategoria" />
                    <p class="fecha_item_categoria">
                        { categoria.fecha}
                    </p>
                    <p class="info_item_categoria">
                        { categoria.info}
                    </p>
                
            </Col>
        );
    });

    return (
        <Row>
            { cards_categoria }
        </Row>
    );
}