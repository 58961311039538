import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Error404 } from '../error/Error404';
import { DirectorioEmergente } from './paginas/DirectorioEmergente';
import { AvisoPrivacidad } from './AvisoPrivacidad';
import { RacismoEnMexico } from './paginas/RacismoEnMexico';
import { Estereotipos } from './paginas/Estereotipos';
import { PrivilegiosMasculinos } from './paginas/PrivilegiosMasculinos';
import { HomeOffice2 } from './webinars/HomeOffice2';

import '../../assets/css/general_paginas.css';
import { DejemosVivirConapred } from './paginas/DejemosVivirConapred';
import { Ciberseguridad } from './webinars/Ciberseguridad';
import { Micromachismo } from './webinars/Micromachismo';
import { Comunicado3006 } from './paginas/comunicados/Comunicado3006';
import { FinanzasRosas } from './paginas/comunicados/FinanzasRosas';
import { Conapred } from './paginas/comunicados/Conapred';
import { PoliticasLeyesAplicables } from './paginas/PoliticasLeyesAplicables';

export const RouterPaginas = () => {
    return(
        <Switch>
            <Route exact path="/directorioemergente" component={DirectorioEmergente} />
            <Route exact path="/aviso-de-privacidad" component={AvisoPrivacidad} />
            <Route exact path="/dejemos-vivir-al-conapred-fortalezcamos-nuestras-instituciones" component={DejemosVivirConapred} />
            <Route exact path="/el-racismo-en-mexico-tan-arraigado-como-su-historia-misma" component={RacismoEnMexico} />
            <Route exact path="/estereotipos" component={Estereotipos} />
            <Route exact path="/privilegios-masculinos" component={PrivilegiosMasculinos} />
            <Route exact path="/home-office-2-0-planeacion-y-organizacion-en-la-nueva-normalidad" component={HomeOffice2} />
            <Route exact path="/la-ciberseguridad-como-elemento-esencial-para-el-exito-de-una-empresa" component={Ciberseguridad} />
            <Route exact path="/micromachismos" component={Micromachismo} />
            <Route exact path="/comunicado3006cu" component={Comunicado3006} />
            <Route exact path="/finanzas-rosas" component={FinanzasRosas} />
            <Route exact path="/dejemos-vivir-al-conapred-fortalezcamos-nuestras-instituciones" component={Conapred} />
            <Route exact path="/politicas-de-cumplimiento" component={PoliticasLeyesAplicables} />
            <Route path="*" component={Error404} />
        </Switch>
    );
} 