import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import 'antd/dist/antd.css';

import './App.css';

import { Master } from './components/sitio/master/Master';

function App() {
  return (
   <>
      <Switch>
        <Route path="/">
          <Master />
        </Route>
      </Switch>
    </>
    

  );
}

export default App;
