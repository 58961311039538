import React, { useState } from 'react';
import { Row, Col, Typography } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import { CarouselBanners } from './CarouselBanners';
import { CarouselServicios } from './CarouselServicios';
import { CarouselEventos } from './CarouselEventos';
import { SeccionEventos } from './SeccionEventos';
import { CarouselClientes } from './CarouselClientes';

import '../../../assets/css/sitio/home.css';

import src_img_bascula_quienes_somos from '../../../assets/images/sitio/home/Maf_QuienesSomos_ICONO.png';


const { Title, Paragraph } = Typography;

export const Home = () => {

    const [show_texto_quienes_somos, setShowTextoQuienesSomos] = useState(false);
    const [tipo_servicio, setTipoServicio] = useState('privado');

    return (
        <>
            <Row style={{display:'non'}}>
                <Col span={24}>
                    <CarouselBanners />
                </Col>
            </Row>
            <Row style={{display:'non'}}>
                <Col xs={{span:22, offset:1}} sm={{span:22, offset:1}} md={{span:20, offset:2}} className="contenedor_quienes_somos">
                    <Row align="middle">
                        <Col xs={{span:24, offset:0}} md={{span:18,offset:0}}>
                            <Row>
                                <Col xs={{span:0, offset:0}} md={{span:24, offset:0}}>
                                <Title className="titulo_quienes_somos">¿QUIÉNES SOMOS?</Title>
                                </Col>
                                <Col xs={{span:0, offset:0}} md={{span:11, offset:0}}>
                                    <Paragraph className="texto_quienes_somos">
                                        Somos una Consultoría enfocada en el diagnóstico,
                                        diseño, implementación y evaluación de estrategias
                                        integrales y transversales. Estamos especializados
                                        en materia corporativa, jurídica y de prospectiva
                                        legislativa, así como en el ámbito electoral, de
                                        comunicación y relaciones públicas para
                                        empresas, asociaciones y cámaras empresariales,
                                        instituciones gubernamentales y organizaciones de
                                        la sociedad civil.
                                    </Paragraph>
                                </Col>
                                <Col xs={{span:0, offset:0}} md={{span:11, offset:1}}>
                                    <Paragraph className="texto_quienes_somos">
                                        A través de nuestro equipo multidisciplinario de
                                        profesionales, nos enfocamos en la identificación
                                        de áreas de oportunidad o de riesgo con el fin de
                                        proponer soluciones prácticas y eficaces de acuerdo
                                        con las necesidades y objetivos de nuestros clientes.
                                        Contamos con una sólida metodología de trabajo
                                        que permite desarrollar planes estratégicos para
                                        garantizar óptimos resultados.
                                    </Paragraph>
                                </Col>

                                <Col xs={{span:24, offset:0}} md={{span:0, offset:0}}>
                                <Title className="titulo_quienes_somos_movil">¿QUIÉNES SOMOS?</Title>
                                </Col>
                                <Col xs={{span:22, offset:1}} md={{span:0, offset:0}}>
                                    <Paragraph className="texto_quienes_somos_movil">
                                        Somos una Consultoría enfocada en el diagnóstico,
                                        diseño, implementación y evaluación de estrategias
                                        integrales y transversales. Estamos especializados

                                        {show_texto_quienes_somos ?
                                            (
                                                <>
                                                    en materia corporativa, jurídica y de prospectiva
                                                    legislativa, así como en el ámbito electoral, de
                                                    comunicación y relaciones públicas para
                                                    empresas, asociaciones y cámaras empresariales,
                                                    instituciones gubernamentales y organizaciones de
                                                    la sociedad civil.
                                                    <br /><br />
                                                    A través de nuestro equipo multidisciplinario de
                                                    profesionales, nos enfocamos en la identificación
                                                    de áreas de oportunidad o de riesgo con el fin de
                                                    proponer soluciones prácticas y eficaces de acuerdo
                                                    con las necesidades y objetivos de nuestros clientes.
                                                    Contamos con una sólida metodología de trabajo
                                                    que permite desarrollar planes estratégicos para
                                                    garantizar óptimos resultados.
                                                    <br />
                                                    <Paragraph style={{textAlign:'center'}}>
                                                        <img src={src_img_bascula_quienes_somos} className="img_bascula_quienes_somos_movil" />
                                                    </Paragraph>
                                                    <br />
                                                    <Paragraph className="icono_leer" onClick={() => { setShowTextoQuienesSomos(false)}}>
                                                        <MinusCircleOutlined /> LEER MENOS
                                                    </Paragraph>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                <br /><br />
                                                <Paragraph className="icono_leer" onClick={() => { setShowTextoQuienesSomos(true)}}>
                                                    <PlusCircleOutlined /> LEER MÁS
                                                </Paragraph>
                                                </>
                                            )
                                        }
                                    </Paragraph>
                                    
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={{span:0, offset:0}} md={{span:6,offset:0}} className="contenedor_img_bascula_quienes_somos">
                            <img src={src_img_bascula_quienes_somos} className="img_bascula_quienes_somos" />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="contenedor_servicios" style={{display:'non'}}>
                <Col xs={{span:18, offset:3}} md={{span:18, offset:3}} className="contenedor_titulo_seccion_servicios" >
                    <Row align="middle">
                        <Col xs={{span:0, offset:0}} md={{span:24, offset:0}}>
                        <Paragraph className="titulo_seccion_servicios_home" >NUESTROS SERVICIOS</Paragraph>
                        </Col>
                        <Col xs={{span:18, offset:3}} md={{span:0, offset:0}}>
                        <Paragraph className="titulo_seccion_servicios_home_movil" >NUESTROS SERVICIOS</Paragraph>
                        </Col>
                        <Col xs={{span:20, offset:2}}  md={{span:8, offset:0}} className={tipo_servicio === 'privado' ? "contenedor_tipo_servicio_home_activo" : "contenedor_tipo_servicio_home"}>
                            <Paragraph className="texto_tipo_servicio" onClick={() => { setTipoServicio('privado'); }}>SECTOR PRIVADO</Paragraph>
                        </Col>
                        <Col xs={{span:20, offset:2}}  md={{span:8, offset:0}} className={tipo_servicio === 'publico' ? "contenedor_tipo_servicio_home_activo" : "contenedor_tipo_servicio_home"}>
                            <Paragraph className="texto_tipo_servicio" onClick={() => { setTipoServicio('publico'); }}>SECTOR PÚBLICO</Paragraph>
                        </Col>
                        <Col xs={{span:20, offset:2}}  md={{span:8, offset:0}} className={tipo_servicio === 'profesionalizacion' ? "contenedor_tipo_servicio_home_activo" : "contenedor_tipo_servicio_home"}>
                            <Paragraph className="texto_tipo_servicio" onClick={() => { setTipoServicio('profesionalizacion'); }}>PROFESIONALIZACIÓN</Paragraph>
                        </Col>
                    </Row>
                </Col>
                <Col></Col>
                <Col xs={{span:0, offset:0}} sm={{span:0, offset:0}} md={{span:0, offset:0}} lg={{span:24, offset:0}} >
                    <CarouselServicios tipo_servicio={tipo_servicio} num_items={3} />
                </Col>
                <Col xs={{span:0, offset:0}} sm={{span:0, offset:0}} md={{span:24, offset:0}} lg={{span:0, offset:0}} >
                    <CarouselServicios tipo_servicio={tipo_servicio} num_items={3} />
                </Col>
                <Col xs={{span:0, offset:0}} sm={{span:24, offset:0}} md={{span:0, offset:0}} lg={{span:0, offset:0}} >
                    <CarouselServicios tipo_servicio={tipo_servicio} num_items={2} />
                </Col>
                <Col xs={{span:24, offset:0}} sm={{span:0, offset:0}} md={{span:0, offset:0}} lg={{span:0, offset:0}} >
                    <CarouselServicios tipo_servicio={tipo_servicio} num_items={1} />
                </Col>
            </Row>
            <Row className="contendor_seccion_eventos" style={{display:'non'}}>
                <Col xs={{span:0, offset:0}} md={{span:20, offset:2}}>
                <Paragraph className="titulo_seccion_evento_movil" >PRÓXIMOS EVENTOS</Paragraph>
                    <p className="leyenda_eventos_movil">“Nos encontramos trabajando en el desarrollo de próximos eventos. ¡Espéralos”</p>
                </Col>
                <Col xs={{span:24, offset:0}} md={{span:0, offset:0}}>
                    <Paragraph className="titulo_seccion_evento_movil" >PRÓXIMOS EVENTOS</Paragraph>
                    <p className="leyenda_eventos_movil">“Nos encontramos trabajando en el desarrollo de próximos eventos. ¡Espéralos”</p>
                    
                </Col>
            </Row>
            <Row className="contenedor_seccion_clientes" style={{display:'non'}}>
                <Col xs={{span:0, offset:0}} md={{span:20, offset:2}}>
                    <Paragraph className="titulo_seccion_clientes">NUESTROS CLIENTES</Paragraph>
                    <CarouselClientes num_items={3} />
                </Col>
                <Col xs={{span:24, offset:0}} md={{span:0, offset:0}}>
                <Paragraph className="titulo_seccion_clientes_movil">NUESTROS CLIENTES</Paragraph>
                    <CarouselClientes num_items={2} />
                </Col>
            </Row>
            
        </>
    );
}
