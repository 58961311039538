import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Button } from 'antd';

import img_src_error_404 from '../../assets/images/error/Maf_error404.png';

export const Error404 = () => {

    const location = useLocation();

    return(
        <>
            <Row align="middle">
                <Col xs={{span:0, offset:0}} md={{span:24, offset:0}}>
                <Col span={24} style={{color:'white', backgroundColor:'#fc5d17', textAlign:'center', fontSize:'30px', wordSpacing:'10px'}}>
                    Lo sentimos <strong>"{location.pathname}"</strong> no está disponible
                </Col>
                <Col span={18} offset={3} style={{textAlign:'center'}}>
                    <p style={{textAlign:'center',color:'black', fontSize:'30px', letterSpacing:'2px', wordSpacing:'10px'}}>Es posible que el enlace que seguiste este roto o se haya eliminado de la página</p>
                    <img src={img_src_error_404} style={{width:'40%'}} />
                    <br />
                    <Link to="/"><Button style={{backgroundColor:'#fc5d17', color:'white'}}>Ir al inicio</Button></Link>
                    <br /><br />
                </Col>
                </Col>

                <Col xs={{span:24, offset:0}} md={{span:0, offset:0}}>
                    <br />
                <Col span={24} style={{color:'white', backgroundColor:'#fc5d17', textAlign:'center', fontSize:'16px', wordSpacing:'4px', paddingLeft:'20px', paddingRight:'20px'}}>
                    Lo sentimos <strong>"{location.pathname}"</strong> no esta disponible
                </Col>
                <Col span={20} offset={2} style={{textAlign:'center'}}>
                    <p style={{textAlign:'center',color:'black', fontSize:'16px', letterSpacing:'2px', wordSpacing:'6px'}}><br /><strong>Es posible que el enlace que seguiste este roto o se haya eliminado de la página</strong></p>
                    <img src={img_src_error_404} style={{width:'80%'}} />
                    <br />
                    <Link to="/"><Button style={{backgroundColor:'#fc5d17', color:'white'}}>Ir al inicio</Button></Link>
                </Col>
                </Col>
                
                
            </Row>
        </>
    );
}