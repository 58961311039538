import React from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Carousel, Typography, Divider } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import img_asesoria_juridica from '../../../assets/images/sitio/home/servicios_privados/Maf_AsesoriaJuridica_Home.jpg';
import img_asuntos_corporativos from '../../../assets/images/sitio/home/servicios_privados/Maf_AsuntosCorporativos_home.jpg';
import img_consultoria_empresarial from '../../../assets/images/sitio/home/servicios_privados/Maf_ConsultoriaEmpresarial_home.jpg';
import img_mercadotecnia_privada from '../../../assets/images/sitio/home/servicios_privados/Maf_Mercadotecnia_home.jpg';
import img_servicios_digitales_privada from '../../../assets/images/sitio/home/servicios_privados/Maf_ServiciosDigitales_home.jpg';
import img_asesoria_politica_desarrollo_social from '../../../assets/images/sitio/home/servicios_publicos/Maf_AsesoriaPolitica_Home.jpg';
import img_auditorias_servicios from '../../../assets/images/sitio/home/servicios_publicos/Maf_AuditoriasYServiciosDeCalidad_Home.jpg';
import img_estrategias_seguridad from '../../../assets/images/sitio/home/servicios_publicos/Maf_EstrategiaDeSeguridadYJusticia_Home.jpg';
import img_mercadotecnia_publica from '../../../assets/images/sitio/home/servicios_publicos/Maf_MercadotecniaPublico_Home.jpg';
import img_planeacion_reingenieria from '../../../assets/images/sitio/home/servicios_publicos/Maf_PlaneacionYReintegracionDeProcesos_Home.jpg';
import img_servicios_digitales_publica from '../../../assets/images/sitio/home/servicios_publicos/Maf_ServiciosDigitalesPublico_Home.jpg';
import img_otros_servicios from '../../../assets/images/sitio/home/servicios_publicos/Maf_OtrosServicios_Home.jpg';
import img_calidad from '../../../assets/images/sitio/home/servicios_profesionalizacion/Maf_Calidad_Home.jpg';
import img_justicia_social from '../../../assets/images/sitio/home/servicios_profesionalizacion/Maf_JusticiaSocial_Home.jpg';
import img_liderazgo from '../../../assets/images/sitio/home/servicios_profesionalizacion/Maf_Liderazgo_Home.jpg';
import img_planeacion from '../../../assets/images/sitio/home/servicios_profesionalizacion/Maf_Planeacion_Home.jpg';

const { Paragraph } = Typography;

const servicios_privados = [
    {index:0, nombre: 'Asesoría Jurídica', url:"/servicios/privado/asesoria-juridica",img_src: img_asesoria_juridica, texto: `Conoce nuestros servicios de asesoría jurídica en el área mercantil, corporativa, penal y civil.` },
    {index:1, nombre: 'Asuntos Corporativos', url:"/servicios/privado/asuntos-corporativos", img_src: img_asuntos_corporativos , texto: `Te asesoramos y desarrollamos estrategias convenientes para tu empresa.` },
    {index:2, nombre: 'Consultoria Empresarial', url:"/servicios/privado/consultoria-empresarial", img_src: img_consultoria_empresarial , texto: `Contamos con servicios especializados de consultoría empresarial. ¡Conócelos!` },
    {index:3, nombre: 'Mercadotecnia', url:"/servicios/privado/mercadotecnia", img_src: img_mercadotecnia_privada , texto: `Conoce los servicios de mercadotecnia que tenemos para tu empresa.` },
    {index:4, nombre: 'Servicios Digitales', url:"/servicios/privado/servicios-digitales", img_src: img_servicios_digitales_privada , texto: `¡Unete al mundo digital! Conoce nuestros servicios.` }
];

const servicios_publicos = [
    {index:0, nombre: 'Asesoría Política y Desarrollo Social', url:"/servicios/publico/asesoria-politica-desarrollo-social", img_src: img_asesoria_politica_desarrollo_social, texto: `Conoce los serviios de asesoría política y desarrollo social que tenemos para ti` },
    {index:1, nombre: 'Auditorías y Servicios de Calidad', url:"/servicios/publico/auditorias-servicios-calidad", img_src: img_auditorias_servicios, texto: `Somos expertosen auditorías y consultoría para tu empresa` },
    {index:2, nombre: 'Estrategia de Seguridad y Justicia', url:"/servicios/publico/estrategia-seguridad-justicia", img_src: img_estrategias_seguridad, texto: `Desarrolla estrategias de seguridad y justicia penal para tu empresa.` },
    {index:3, nombre: 'Mercadotecnia', url:"/servicios/publico/mercadotecnia", img_src: img_mercadotecnia_publica, texto: `Conoce los servicios de mercadotecnia que tenemos para tu empresa` },
    {index:4, nombre: 'Planeación y Reintegración de Procesos', url:"/servicios/publico/planeacion-reingenieria-procesos", img_src: img_planeacion_reingenieria , texto: `Haz que tus procesos tengan estrategia y sean más eficaces` },
    {index:5, nombre: 'Servicios Digitales', url:"/servicios/publico/servicios-digitales", img_src: img_servicios_digitales_publica, texto: `¡Unete al mundo digital! Conoce nuestros servicios para tu empresa` },
    {index:6, nombre: 'Otros Servicios', url:"/servicios/publico/otros-servicios", img_src: img_otros_servicios, texto: `Contamos con diversos servicios para el sector público. ¡Conócenos!` }
];

const servicios_profesionalizacion = [
    {index:0, nombre: 'Calidad en el Servicio', url:"/servicios/profesionalizacion/calidad-servicio", img_src: img_calidad, texto: `Desarrolla estrategias para mejorar la calidad en los servicios  que brinda tu empresa` },
    {index:1, nombre: 'Sistema de Justicia Social', url:"/servicios/profesionalizacion/sistema-justicia-social", img_src: img_justicia_social, texto: `Contamos con amplio conocimiento en el tema de justicia social. ¡Conócemos!` },
    {index:2, nombre: 'Liderazgo', url:"/servicios/profesionalizacion/liderazgo", img_src: img_liderazgo, texto: `Te ayudamos a desarrollar tus habilidades de liderazgo.` },
    {index:3, nombre: 'Planeación', url:"/servicios/profesionalizacion/planeacion", img_src: img_planeacion, texto: `Organiza y planea de manera adecuada las estrategias para tu empresa.` }
];

export const CarouselServicios = (params) => {

    const { tipo_servicio, num_items } = params;

    const cambiarCarousel = React.createRef();

    let items_servicios;

    switch (tipo_servicio) {
        case 'privado': items_servicios = servicios_privados; break;
        case 'publico': items_servicios = servicios_publicos; break;
        case 'profesionalizacion': items_servicios = servicios_profesionalizacion; break;
        default: items_servicios = []; break;
    }

    let div_carosuel = [];
    let count_divs_carousel = Math.ceil(items_servicios.length / num_items);

    for(let i=0; i < count_divs_carousel; i++){

        div_carosuel[i] = 
            //filtrar
            items_servicios.filter(servicio => {
                if(servicio.index >= (num_items*(i)) && servicio.index < (num_items*(i+1))){
                    return true;
                }else{
                    return false;
                }
            })
            //crear item
            .map(servicio => {
                return (
                    <Col xs={24} sm={12} md={8} lg={8} className="contenedor_servicio">
                        <Paragraph className="texto_banner_servicios_home">
                            <br />
                            <img src={servicio.img_src} className="img_banner_servicios" />
                            <br />
                            <p className="titulo_servicio_home">{servicio.nombre.toUpperCase()}  
                            </p>
                            <Divider className="division_texto_servicios" />
                            <p className="texto_servicio">{servicio.texto}
                            <br />
                            <Link to={servicio.url} className="link_leer_mas_servicio">Leer más...</Link>
                            </p>
                        </Paragraph>
                    </Col>
                );
            });
            ;


        div_carosuel[i] = (
            <div>
                <Row>
                    { div_carosuel[i] }
                </Row>
            </div>
        );
    }

    const show_divs = div_carosuel.map(div => {
            return(
                div
            );
        }
    );


    return (
        <Row justify="space-around" align="middle">
            <Col xs={{ span: 3 , offset: 0 }} md={{ span: 3, offset: 0 }} style={{textAlign:'center'}}>
                <LeftOutlined className="flechas_cambiar_carousel" onClick={() => { cambiarCarousel.current.prev() }} />
            </Col>
            <Col xs={{ span: 18, offset: 0 }} md={{ span: 18, offset: 0 }}>
                <Carousel autoplay dots={false} ref={cambiarCarousel}>
                    { show_divs }
                </Carousel>
            </Col>
            <Col xs={{ span: 3, offset: 0 }} md={{ span: 3, offset: 0 }} style={{textAlign:'center'}}>
                <RightOutlined className="flechas_cambiar_carousel" onClick={() => { cambiarCarousel.current.next() }} />
            </Col>
        </Row>
    );

};