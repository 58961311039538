import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Typography } from 'antd';

import { MostrarServicios } from './MostrarServicios';

import '../../../assets/css/sitio/servicios.css';

const { Paragraph } = Typography;

export const Servicios = () => {
    const { tipo  } = useParams();
    const [tipo_servicio, setTipoServicio] = useState(tipo === 'publico' ? 'publico' : tipo === 'profesionalizacion' ? 'profesionalizacion' : 'privado');
    
    console.log(tipo);


    return (
        <>
            <Row className="banner_servicios">
                <Col xs={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }}>
                    <Paragraph className="titulo_servicios">
                        <strong>SOMOS EXPERTOS EN EL DESARROLLO</strong><br />
                            DE PLANES Y ESTRATEGIAS CON<br />
                            RESULTADOS ÓPTIMOS.
                    </Paragraph>
                </Col>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}>
                    <Paragraph className="titulo_servicios_movil">
                        SOMOS EXPERTOS EN EL DESARROLLO
                        DE PLANES Y ESTRATEGIAS CON
                        RESULTADOS ÓPTIMOS.
                    </Paragraph>
                </Col>
            </Row>
            <Row className="seccion_servicios">
                <Col xs={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }}>
                    <Paragraph className="titulo_seccion_servicios" >NUESTROS SERVICIOS</Paragraph>
                </Col>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}>
                    <Paragraph className="titulo_seccion_servicios_movil" >NUESTROS SERVICIOS</Paragraph>
                </Col>
                <Col span={20} offset={2}>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} md={{ span: 8, offset: 0 }} className={tipo_servicio === 'privado' ? "contenedor_tipo_servicio_activo" : "contenedor_tipo_servicio"} onClick={() => { setTipoServicio('privado'); }}>
                            SECTOR PRIVADO
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} md={{ span: 8, offset: 0 }} className={tipo_servicio === 'publico' ? "contenedor_tipo_servicio_activo" : "contenedor_tipo_servicio"} onClick={() => { setTipoServicio('publico'); }}>
                            SECTOR PÚBLICO
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} md={{ span: 8, offset: 0 }} className={tipo_servicio === 'profesionalizacion' ? "contenedor_tipo_servicio_activo" : "contenedor_tipo_servicio"} onClick={() => { setTipoServicio('profesionalizacion'); }}>
                            PROFESIONALIZACIÓN
                        </Col>
                    </Row>
                </Col>
                <Col xs={{span:0, offset:0}} md={{span:24, offset:0}}>
                    <MostrarServicios tipo_servicio={tipo_servicio} carousel={false} />
                </Col>
                <Col xs={{span:24, offset:0}} md={{span:0, offset:0}}>
                <MostrarServicios tipo_servicio={tipo_servicio} carousel={true} />
                </Col>
            </Row>
        </>
    );
}