import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

import '../../../assets/css/sitio/noticias.css';
import img_src_nota from '../../../assets/images/sitio/noticias/Maf_911.jpg';

const notas = [
    {
        img_src: img_src_nota,
        titulo: 'TITULO DE LA NOTA 1',
        info: 'dsadsa dasdasd asdsad asdas',
        url: '#'
    },
    {
        img_src: img_src_nota,
        titulo: 'TITULO DE LA NOTA 2',
        info: 'dsadsa dasdasd asdsad asdas',
        url: '#'
    },
    {
        img_src: img_src_nota,
        titulo: 'TITULO DE LA NOTA 3',
        info: 'dsadsa dasdasd asdsad asdas',
        url: '#'
    }
];

export const Notas = () => {

    const col_notas = notas.map(nota => {

        return (
            <Link to={nota.url} >
                <Row className="contenedor_nota">
                    <Col span={8}>
                        <img src={nota.img_src} className="img_nota" />
                    </Col>
                    <Col span={15} offset={1}>
                        <p className="titulo_nota">{nota.titulo}</p>
                        <p className="texto_nota">{nota.info}</p>
                    </Col>
                </Row>
            </Link>
        );
    });

    return (
        <div>
            {col_notas}
        </div>


    );
}