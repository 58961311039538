import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Notas } from './Notas';
import { CardsCategoria } from './CardsCategoria';

import img_src_principal from '../../../assets/images/sitio/noticias_doctora/Maf_12PublicacionesMaf.jpg'

export const Publicaciones = () => {

    const [categoria, setCategoria] = useState('Publicaciones');
    const [texto_categoria, setTextoCategoria] = useState('Publicaciones');

      const handleMenuClick = (key) =>{
          console.log(key);
        setCategoria(key.key);
        setTextoCategoria(key.item.props.texto);
      }

      const menu_categorias = (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key="responsabilidad_social" texto="Responsabilidad Social" >Responsabilidad Social</Menu.Item>
          <Menu.Item key="tips_empresariales" texto="Tips Empresariales" >Tips Empresariales</Menu.Item>
          <Menu.Item key="violencia_de_genero" texto="Violencia de Género" >Violencia de Género</Menu.Item>
          <Menu.Item key="publicaciones" texto="publicaciones" >Publicaciones</Menu.Item>
          <Menu.Item key="articulos" texto="publicaciones" >Artículos</Menu.Item>
          <Menu.Item key="todos" texto="todos" >Todos</Menu.Item>
        </Menu>
      );

    return (
        <Row>    
            <Col span={24}>
                <Link to="/la-ciberseguridad-como-elemento-esencial-para-el-exito-de-una-empresa"><img src={img_src_principal} className="img_principal_noticias" /></Link>
            </Col>
            <Col xs={{ span: 18, offset: 3 }} sm={{ span: 16, offset: 4 }} md={{ span: 0, offset: 0 }}>
                <Row justify="space-around" align="middle">
                    <Col span={8} style={{textAlign:'left'}}>
                    <Dropdown overlay={menu_categorias}>
      <Button>
        Categorias <DownOutlined />
      </Button>
    </Dropdown>
                    </Col>
                    <Col span={16}>
    <p class="titulo_opcion_categoria">{texto_categoria}</p>
                    </Col>
                </Row>
            
            </Col>
            <Col xs={{span:0, offset:0}} md={{span:24, offset:0}} className="contenedor_seccion_categorias"></Col>
            <Col span={24}>
            <Row>
            <Col xs={{ span: 20, offset: 2 }} md={{ span: 14, offset: 2 }} >
                <CardsCategoria categoria={categoria} />
            </Col>
            <Col xs={{ span: 0, offset: 0 }} md={{ span: 5, offset: 1 }}>
                <Row>
                    <Col className="contenedor_categorias" span={24}>
                        <p className="titulo_categorias">Categorías</p>
                        <p className="opciones_categoria">
                            <span className={categoria === 'responsabilidad_social' ? 'opcion_categoria_activa' : 'opcion_categoria'} onClick={() => setCategoria('responsabilidad_social')}>Responsabilidad Social</span><br />
                            <span className={categoria === 'tips_empresariales' ? 'opcion_categoria_activa' : 'opcion_categoria'} onClick={() => setCategoria('tips_empresariales')}>Tips Empresariales</span><br />
                            <span className={categoria === 'violencia_de_genero' ? 'opcion_categoria_activa' : 'opcion_categoria'} onClick={() => setCategoria('violencia_de_genero')}>Violencia de Género</span><br />
                            <span className={categoria === 'articulos' ? 'opcion_categoria_activa' : 'opcion_categoria'} onClick={() => setCategoria('articulos')}>Artículos</span><br />
                            <span className={categoria === 'publicaciones' ? 'opcion_categoria_activa' : 'opcion_categoria'} onClick={() => setCategoria('publicaciones')}>Publicaciones</span><br />
                            <span className={categoria === 'todos' ? 'opcion_categoria_activa' : 'opcion_categoria'} onClick={() => setCategoria('todos')}>Todos</span><br />
                        </p>
                    </Col>
                   
                </Row>

            </Col>
            </Row>
            </Col>
            
            
        </Row>
    );

}