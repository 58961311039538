import React from 'react';
import { Row, Col, Typography, Button } from 'antd';

import '../../../assets/css/sitio/cursos.css'
import { InfoCursos } from './InfoCursos';
const { Paragraph } = Typography;

export const Cursos = () => {

    return(
        <>
            <Row className="banner_cursos">
                <Col xs={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }}>
                    <Paragraph className="titulo_cursos">
                        <strong>SOMOS EXPERTOS EN EL DESARROLLO</strong><br />
                            DE PLANES Y ESTRATEGIAS CON<br />
                            RESULTADOS ÓPTIMOS.<br />
                            <a href="mailto:contacto@mafyasociados.com"><Button>Contáctanos</Button></a>
                    </Paragraph>
                </Col>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}>
                    <Paragraph className="titulo_cursos_movil">
                    SOMOS EXPERTOS EN EL DESARROLLO
                        DE PLANES Y ESTRATEGIAS CON
                        RESULTADOS ÓPTIMOS.<br />
                        <a href="mailto:contacto@mafyasociados.com"><Button>Contáctanos</Button></a>
                    </Paragraph>
                </Col>
            </Row>
            <Row className="contenedor_cursos_impartidos">
                <Col xs={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }}>
                    <Paragraph className="titulo_cursos_impartidos">
                        <strong>CURSOS IMPARTIDOS</strong>
                    </Paragraph>
                </Col>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}>
                    <Paragraph className="titulo_cursos_impartidos_movil">
                        CURSOS IMPARTIDOS
                    </Paragraph>
                </Col>
                <Col xs={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }}>
                    <InfoCursos render_xs={false} />
                </Col>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}>
                    <InfoCursos render_xs={true} />
                </Col>
            </Row>
        </>
    );
}
