import React from 'react';
import { Row, Col } from 'antd';

import img_src_header from '../../../../assets/images/paginas/congreso_union/header_congreso_union.jpg'

export const Comunicado3006 = () => {
    return (
        <Row >
            <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }}>
            <img src={img_src_header} style={{ width: '100%' }} />
            </Col>
            <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} style={{ textAlign: 'right' }}>
                
                <br />
                <span className="color_gris_claro texto_tamano" >30 de junio de 2020</span>
                <br />
                <p className="color_gris_claro texto_tamano" style={{ textAlign: 'justify' }}>
                    <strong>Antecedentes</strong>
                    <br /><br />
El 29 de junio de 2020, ambas Cámaras del Congreso de la Unión publicaron en el Diario Oficial de la Federación sus respectivas convocatorios para celebrar un Periodo de Sesiones Extraordinarias durante el Segundo Receso del Segundo Año de Ejercicio de la Sexagésima Cuarta Legislatura.
<br /><br />
                    <strong>Se acordó discusión y votación de los siguientes asuntos: </strong>
                    <br /><br />
•	Dictamen de las Comisiones Unidas de Economía; de Salud; y de Estudios Legislativos, Segunda, que propone la aprobación del proyecto de Decreto por el que se expide la Ley Federal de Protección a la Propiedad Industrial.
<br />
•	Dictamen de las Comisiones Unidas de Cultura; y de Estudios Legislativos, Segunda, que propone la aprobación del proyecto de Decreto por el que se reforman y adicionan diversas disposiciones de la Ley Federal del Derecho de Autor.
<br />
•	Dictamen de las Comisiones Unidas de Justicia; y Estudios Legislativos, Segunda, que propone la aprobación del proyecto de Decreto por el que se reforman y adicionan diversas disposiciones del Código Penal Federal.
<br />
•	Dictamen de las Comisiones Unidas de Hacienda y Crédito Público; y de Estudios Legislativo, Segunda, que propone la aprobación del proyecto de Decreto por el que se expide la Ley de los Impuestos Generales de Importación y de Exportación y se reforman y adicionan diversas disposiciones de la Ley Aduanera.
<br />
•	Dictamen de las Comisiones Unidas de Hacienda y Crédito Público; y de Estudios Legislativo, Segunda, que propone la aprobación del proyecto de Decreto por el que se expide la Ley de Infraestructura de la Calidad y se abroga la Ley Federal sobre Metrología y Normalización.
<br />
De forma exclusiva el Senado de la República, listó el siguiente asunto:
<br />
•	Dictamen de las Comisiones Unidas de Relaciones Exteriores América del Norte; de Relaciones Exteriores; y de Medio Ambiente, Recursos Naturales y Cambio Climático, que propone la aprobación del Acuerdo en materia de Cooperación Ambiental entre los gobiernos de los Estados Unidos Mexicanos, de los Estados Unidos de América y de Canadá, firmado en las ciudades de México, Washington D.C. y Ottawa, el 30 de noviembre, el 11 y el 18 de diciembre de 2018, respectivamente.
<br /><br />
                    <strong>Consideraciones</strong>
                    <br /><br />
Con la finalidad de realizar una armonización legislativa de la normatividad mexicana con el texto del T-MEC, se hicieron las siguientes adecuaciones:
<br /><br />
•	Dictamen de las Comisiones Unidas de Economía; de Salud; y de Estudios Legislativos, Segunda, que propone la aprobación del proyecto de Decreto por el que se expide la Ley Federal de Protección a la Propiedad Industrial.
<br /><br />
La Ley Federal de Protección a la Propiedad Industrial, consta de 410 artículos con 18 artículos transitorios, esta nueva ley tiene por objeto proteger la propiedad industrial mediante la regulación y otorgamiento de patentes de invención, registros de modelos de utilidad, diseños industriales, esquemas de trazado de circuitos integrados, marcas y avisos comerciales, publicación de nombres comerciales, declaración de protección de denominaciones de origen e indicaciones geográficas; Regular los secretos industriales; prevenir los actos que atenten contra la propiedad industrial; promover y fomentar la actividad inventiva de aplicación industrial y promover la difusión de los conocimientos tecnológicos en el país.
<br /><br />
La aplicación de la Ley corresponde al Ejecutivo Federal por conducto del Instituto Mexicano de la Propiedad Industrial.
<br /><br />
Y entrará en vigor en los 90 días hábiles siguientes a su publicación en el Diario Oficial de la Federación.
<br /><br />
En el Senado de la República, del dictamen original se aprobaron dos reservas, una al artículo 5<sup>1</sup> y una al artículo 402<sup>2</sup>, siendo aprobado el dictamen en lo general y en lo particular.
<br /><br />
En la Cámara de Diputados la minuta fue aprobada en sus términos.
<br /><br />
•	Dictamen de las Comisiones Unidas de Cultura; y de Estudios Legislativos, Segunda, que propone la aprobación del proyecto de Decreto por el que se reforman y adicionan diversas disposiciones de la Ley Federal del Derecho de Autor.
<br /><br />
Ante la difusión que se da a las obras protegidas por la Ley a través de medios electrónicos, la legislación mexicana ha optado por actualizarse para establecer mecanismos que permitan la difusión y regulación de las obras, de tal manera que los autores puedan elegir las plataformas de difusión, así como la autorización o prohibición, en ese orden de ideas ha habido una actualización de las sanciones, esta reforma entrará en vigor el día de su publicación en el Diario Oficial de la Federación.
<br /><br />
En ambas Cámaras del Congreso de la Unión, el dictamen se votó en sus términos.
<br /><br />
•	Dictamen de las Comisiones Unidas de Justicia; y Estudios Legislativos, Segunda, que propone la aprobación del proyecto de Decreto por el que se reforman y adicionan diversas disposiciones del Código Penal Federal.
<br /><br />
Las reformas al Código Penal Federal, implica la protección a los derechos de autor tomando en cuenta los avances tecnológicos a los que las personas tienen acceso, principalmente se ha buscado sancionar la recepción o distribución de la señal de satélite cifrada; la grabación, transmisión o realización de una obra cinematográfico, fenómeno conocido como camcording.
<br /><br />
De igual forma, se sancionará a los que a sabiendas y con fines de lucro realice actividades como eludir la autorización de medios de protección que utilicen los productores, así como quien fabrique, importe, distribuya o rente dispositivos que ayuden a eludir medidas tecnológicas de protección.
<br /><br />
La reforma entrará en vigor al día siguiente de su publicación en el Diario Oficial de la federación.
<br /><br />
En ambas Cámaras el proyecto fue aprobado en sus términos.
<br /><br />
•	Dictamen de las Comisiones Unidas de Hacienda y Crédito Público; y de Estudios Legislativo, Segunda, que propone la aprobación del proyecto de Decreto por el que se expide la Ley de los Impuestos Generales de Importación y de Exportación y se reforman y adicionan diversas disposiciones de la Ley Aduanera.
<br /><br />
Con la finalidad de establecer las tasas de los aranceles a la importación y a la exportación  de mercancías en nuestro país y a la vez establezca la base de clasificación de mercancías para la generación de estadística en el comercio exterior, tomando en cuenta los tratados internacionales firmados por el estado mexicano, por su parte se reforma la ley aduanera con la finalidad de armonizar con el texto de la Ley de los Impuestos Generales de Importación y Exportación, así como para el funcionamiento relativo de los números de identificación comercial.
<br /><br />
El decreto entrará en vigor el día siguiente de su publicación, salvo lo dispuesto en los artículos 1 y 2 fracciones I y II, así como las reglas 1, 2 y 4 de la Ley de los Impuestos Generales de Importación y Exportación, mismas que entrarán en vigor a los 180 días naturales siguientes al de su publicación en el Diario Oficial de la Federación.
<br /><br />
En ambas Cámaras el proyecto fue aprobado en sus términos.
<br /><br />
•	Dictamen de las Comisiones Unidas de Hacienda y Crédito Público; y de Estudios Legislativo, Segunda, que propone la aprobación del proyecto de Decreto por el que se expide la Ley de Infraestructura de la Calidad y se abroga la Ley Federal sobre Metrología y Normalización.
<br /><br />
Con la finalidad de mejorar el desempeño de la actividad económica, brindar certidumbre jurídica, promover la concurrencia de los sectores público, social y privado en la elaboración y observancia de las Normas Oficiales Mexicanas,  establecer mecanismos de coordinación y colaboración en materia de normalización, propiciar la innovación tecnológica, impulsar la creación de mayor infraestructura física y digital, establecer y mantener un Sistema General de Unidades de Medida.
<br /><br />
Esta ley creará los Institutos Designados de Petrología y establecer lo referente a la metrología científica, metrología legal y metrología aplicada.
<br /><br />
El decreto entrará en vigor a los 60 días siguientes de su publicación en el Diario Oficial de la Federación.
<br /><br />
En el Senado de la República, se aprobaron una reserva respecto al artículo 11 primer párrafo<sup>3</sup>.
<br /><br />
En la Cámara de Diputados, la minuta fue aprobada en sus términos.
<br /><br />
•	Dictamen de las Comisiones Unidas de Relaciones Exteriores América del Norte; de Relaciones Exteriores; y de Medio Ambiente, Recursos Naturales y Cambio Climático, que propone la aprobación del Acuerdo en materia de Cooperación Ambiental entre los gobiernos de los Estados Unidos Mexicanos, de los Estados Unidos de América y de Canadá, firmado en las ciudades de México, Washington D.C. y Ottawa, el 30 de noviembre, el 11 y el 18 de diciembre de 2018, respectivamente.

<br /><br />
El acuerdo fue aprobado en el Senado de la República.
<br /><br />
                    <hr style={{ marginLeft: '20px', marginRight: '20px' }} />

                    <br /><br />
                    <small>
                        <sup><strong>1</strong></sup> Reserva  de los Senadores Claudia Ruiz Massieu Salinas, Oscar Ramírez Aguilar y Jorge Alberto Habib Abimerhi. <a href="https://infosen.senado.gob.mx/sgsp/gaceta/64/2/2020-06-29-2/assets/documentos/1_Reservas_Propiedad_Industrial_Claudia_Ruiz_y_Eduardo_Ramirez.pdf" target="_blank" className="link_sin_decoracion">https://infosen.senado.gob.mx/sgsp/gaceta/64/2/2020-06-29-2/assets/documentos/1_Reservas_Propiedad_Industrial_Claudia_Ruiz_y_Eduardo_Ramirez.pdf</a> <br />
                        <sup><strong>2</strong></sup> Reserva del Senador Miguel Ángel Mancera Espinosa. <a href="https://infosen.senado.gob.mx/sgsp/gaceta/64/2/2020-06-29-2/assets/documentos/6_Reservas_Propiedad_Industrial_Sen_Mancera_ACT.pdf" target="_blank" className="link_sin_decoracion">https://infosen.senado.gob.mx/sgsp/gaceta/64/2/2020-06-29-2/assets/documentos/6_Reservas_Propiedad_Industrial_Sen_Mancera_ACT.pdf</a>
                        <br />
                        <sup><strong>3</strong></sup> Reserva propuesta por el Senador José Narro Céspedes. <a href="https://infosen.senado.gob.mx/sgsp/gaceta/64/2/2020-06-29-2/assets/documentos/2_Reservas_Infraestructura_de_Calidad_Sen_Narro_Cespedes.pdf" target="_blank" className="link_sin_decoracion">https://infosen.senado.gob.mx/sgsp/gaceta/64/2/2020-06-29-2/assets/documentos/2_Reservas_Infraestructura_de_Calidad_Sen_Narro_Cespedes.pdf</a>
                    </small>
                </p>

            </Col>
        </Row>
    );
}