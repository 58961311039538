import React from 'react';
import { Link } from "react-router-dom";   
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img_cliente_airbus from '../../../assets/images/sitio/home/clientes/Maf_AIRBUS.jpg';
import img_cliente_atlas from '../../../assets/images/sitio/home/clientes/Maf_ATLAS.jpg';
import img_cliente_cdi from '../../../assets/images/sitio/home/clientes/Maf_CDI.jpg';
import img_cliente_cemex from '../../../assets/images/sitio/home/clientes/Maf_Cemex.jpg';
import img_cliente_cndh from '../../../assets/images/sitio/home/clientes/Maf_CNDH.jpg';
import img_cliente_pgr from '../../../assets/images/sitio/home/clientes/Maf_PGR.jpg';
import img_cliente_segob from '../../../assets/images/sitio/home/clientes/Maf_SEGOB.jpg';


let clientes = [
    { index: 0, img_src: img_cliente_airbus, nombre: 'AIRBUS' },
    { index: 1, img_src: img_cliente_atlas, nombre: 'ATLAS' },
    { index: 2, img_src: img_cliente_cdi, nombre: 'CDI' },
    { index: 3, img_src: img_cliente_cemex, nombre: 'CEMEX' },
    { index: 4, img_src: img_cliente_cndh, nombre: 'CNDH' },
    { index: 5, img_src: img_cliente_pgr, nombre: 'PGR' },
    { index: 6, img_src: img_cliente_segob, nombre: 'SEGOB' }
]

function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

export const CarouselClientes = (params) => {

    const { num_items } = params;


    const settingsClientes = {
        className: "center",
            centerMode: true,
            infinite: true,
            slidesToShow: num_items,
            pauseOnHover:false,
            slidesToScroll: 1,
            autoplay:true,
            arrows:false,
            autoplaySpeed: 200,
            speed:2000
    };

    clientes = shuffle(clientes);

    const div_clientes = clientes.map(cliente => {
        return(
            <div>
                <img src={cliente.img_src} alt={cliente.nombre} className="img_cliente" />
            </div>
        )
    });

    return (
        <div style={{marginTop:'0px', paddingtop:'0px'}}>
            <Slider {...settingsClientes}>
            {div_clientes}
            </Slider>
        </div>
    );

};