import React, { useState } from 'react';
import { Row, Col, Typography, Form, Input, Button, notification  } from 'antd';
import { mail_contacto } from '../../../utils/jwt/sitio';
import ReCAPTCHA from "react-google-recaptcha";
import '../../../assets/css/sitio/contacto.css';

const { Paragraph } = Typography;
const { TextArea } = Input;

const openNotificationWithIcon = (type,title,descripction) => {
  notification[type]({
    message: title,
    description:
      descripction,
  });
};


export const Contacto = () => {

    const [fields, setFields] = useState([
        {
          name: ['nombre'],
          value: '',
        },{
            name: ['correo'],
            value: '',
          },{
            name: ['mensaje'],
            value: '',
          },
      ]);

      let ref_recaptcha = React.createRef();

      const enviarFormContacto = (value) => {

        if(ref_recaptcha.current.getValue() == '' || ref_recaptcha.current.getValue() == ' '){
          openNotificationWithIcon('error','Adevertencia','Verifica no ser un robot');
          return ;
        }

          value['g-recaptcha-response'] = ref_recaptcha.current.getValue();

          mail_contacto(value).then(({ status }) => {
            switch(status){
              case 0:
                openNotificationWithIcon('success','Enviado','Tu mensaje ha sido enviado con éxito');
                break;
                default:
                  openNotificationWithIcon('error','Error','Tu mensaje no pudo ser enviado, inténtalo más tarde');
                  break;
            }
          });

      }

      const CustomizedForm = ({ onChange, fields }) => (
        <Form
          name=""
          layout="vertical"
          fields={fields}
          onFinish={enviarFormContacto}
        >
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[
              {
                required: true,
                message: 'Ingresa un nombre',
              },
            ]}
          >
            <Input className="entrada_texto" />
          </Form.Item>
          <Form.Item
            name="correo"
            label="Correo"
            rules={[
              {
                required: true,
                message: 'Ingresa un correo',
              },
            ]}
          >
            <Input type="email" className="entrada_texto" />
          </Form.Item>
          <Form.Item
            name="mensaje"
            label="Mensaje"
            rules={[
              {
                required: true,
                message: '¿En qué te podemos ayudar?',
              },
            ]}
          >
            <TextArea rows={1} className="entrada_texto" />
          </Form.Item>
          
          <ReCAPTCHA style={{marginTop:'12px',marginBottom:'10px'}} ref={ref_recaptcha}
            sitekey="6LcG0rcZAAAAAI83NOkqDIxMEsyOmIaajurqYOT8"
          />
          <Button htmlType="submit" className="boton_enviar_form_contacto" >ENVIAR</Button>
        </Form>
      );

    return(
        <>
            <Row className="banner_contacto">
                <Col xs={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }}>
                    <Paragraph className="titulo_contacto">
                        <strong>SÍGUENOS EN NUESTRAS REDES SOCIALES</strong><br />
                        Y MANTENTE EN CONTACTO<br />
CON NOSOTROS
                    </Paragraph>
                </Col>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}>
                    <Paragraph className="titulo_contacto_movil">
                    SÍGUENOS EN NUESTRAS REDES SOCIALES
Y MANTENTE EN CONTACTO
CON NOSOTROS
                    </Paragraph>
                </Col>
            </Row>
            <Row align="middle" className="contendor_contacto_mapa">
                <Col xs={{span:0, offset:0}} md={{span:12, offset:0}}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.4553269700973!2d-99.18811354999123!3d19.435925945547357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f8abc2c90de3%3A0x7dc516480c2bb42b!2sAv.%20Isaac%20Newton%20232%2C%20Polanco%2C%20Polanco%20V%20Secc%2C%20Miguel%20Hidalgo%2C%2011560%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses!2smx!4v1594840566604!5m2!1ses!2smx" style={{width:'100%', height:'500px'}} frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </Col>
                <Col xs={{span:20, offset:2}} md={{span:8, offset:2}}>
                    <p className="titulo_contactanos">CONTÁCTANOS</p>
                    <CustomizedForm
        fields={fields}
        onChange={newFields => {
          setFields(newFields);
        }}
      />
                </Col>
            </Row>
        </>
    );
}