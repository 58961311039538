import React from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Carousel, Divider } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import img_integrante_dra_fromow from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/01_Maf_NuestroEquipo_DraFromow.jpg';
import img_integrante_2 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/02_Maf_NuestroEquipo_GralMarioFromow.jpg';
import img_integrante_3 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/03_Maf_NuestroEquipo_JuanNinoTej.jpg';
import img_integrante_4 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/04_Maf_NuestroEquipo_MaDeLosAngelesMolina.jpg';
import img_integrante_5 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/05_Maf_NuestroEquipo_FabioOcampo.jpg';
import img_integrante_6 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/06_Maf_NuestroEquipo_YunuenGarcia.jpg';
import img_integrante_7 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/07_Maf_NuestroEquipo_LourdesGaribay.jpg';
import img_integrante_8 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/08_Maf_NuestroEquipo_EmmanuelMartinez.jpg';
import img_integrante_9 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/09_Maf_NuestroEquipo_NestrorRuiz.jpg';
import img_integrante_10 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/10_Maf_NuestroEquipo_HarumyRamos.jpg';
import img_integrante_11 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/11_Maf_NuestroEquipo_SandraBarrera.jpg';
import img_integrante_12 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/12_Maf_NuestroEquipo_EmilioLira.jpg';
import img_integrante_13 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/13_Maf_NuestroEquipo_IlseHernandez.jpg';
import img_integrante_14 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/14_Maf_NuestroEquipo_SamuelZenteno.jpg';
import img_integrante_15 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/15_Maf_NuestroEquipo_MarioAvila.jpg';
import img_integrante_16 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/16_Maf_NuestroEquipo_StephanieHernandez.jpg';
import img_integrante_17 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/17_Maf_NuestroEquipo_CarlosMaya.jpg';
import img_integrante_18 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/18_Maf_NuestroEquipo_JoseUribe.jpg';
import img_integrante_19 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/19_Maf_NuestroEquipo_CesarAnorve.jpg';
import img_integrante_20 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/20_Maf_NuestroEquipo_EduardoVidal.jpg';
import img_integrante_21 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/21_Maf_NuestroEquipo_CarlosElizarraraz.jpg';

import img_icono_facebook from '../../../assets/images/iconos/facebook_ANARANJADO.png';
import img_icono_twitter from '../../../assets/images/iconos/twitter_ANARANJADO.png';
import img_icono_instagram from '../../../assets/images/iconos/instagram_ANARANJADO.png';
const integrantes = [
    {
        index: 0,
        nombre: 'Dra. Ma. de los Ángeles Fromow Rangel',
        img_src: img_integrante_dra_fromow,
        puesto: 'Directora General',
        mail: 'mfromow@mafyasociados.com',
        redes_sociales: [
            {
                nombre: 'Twitter',
                url_icono: img_icono_twitter,
                url: ''
            },
            {
                nombre: 'Twitter',
                url_icono: img_icono_twitter,
                url: ''
            }
        ]
    },
    { index: 1, nombre: 'Gral. D.E.M. Lic. Mario Guillermo Fromow García', img_src: img_integrante_2, puesto: 'Socio', mail: 'gfromow@mafyasociados.com', redes_sociales: [{ url_icono: '', url: '' }] },
    { index: 2, nombre: 'Mtro. Juan José Niño Tejedor', img_src: img_integrante_3, puesto: 'Coordinador de Relaciones Públicas', mail: 'jnino@mafyasociados.com', redes_sociales: [{ url_icono: '', url: '' }] },
    { index: 3, nombre: 'Lic. María de los Ángeles Molina Amaya', img_src: img_integrante_4, puesto:'Coordinadora de Asuntos Corporativos', mail: 'mmolina@mafyasociados.com',redes_sociales: []},
    { index: 4, nombre: 'Lic. Fabio Alberto Emmanuel Ocampo Vázquez', img_src: img_integrante_5, puesto:'Coordinador de Asuntos Jurídicos y Legislativos', mail: 'focampo@mafyasociados.com',redes_sociales: []},
    { index: 5, nombre: 'Lic. Yunuen García Herrera', img_src: img_integrante_6, puesto:'Coordinadora de la Oficina de la Directora General', mail: 'ygarcia@mafyasociados.com',redes_sociales: []},
    { index: 6, nombre:'Mtra. Ma. de Lourdes Garibay Bordon', img_src: img_integrante_7, puesto:'Coordinadora de Administración y Finanzas', mail: 'mgaribay@mafyasociados.com',redes_sociales: []},
    { index: 7, nombre:'Lic. Emmanuel Martínez Suazo', img_src: img_integrante_8, puesto:'Coordinador de Capacitación y Prevención', mail: 'emartinez@mafyasociados.com',redes_sociales: []},
    { index: 8, nombre:'Néstor Gustavo Ruíz Hernández', img_src: img_integrante_9, puesto:'Consultor Junior de Capacitación y Prevención', mail: 'nruiz@mafyasociados.com',redes_sociales: []},
    { index: 9, nombre:'Lic. Harumy Ramos Elizalde', img_src: img_integrante_10, puesto:'Consultora Senior de Asuntos Jurídicos', mail: 'hramos@mafyasociados.com',redes_sociales: []},
    { index: 10, nombre:'Lic. Sandra Yadira Barrera Juárez', img_src: img_integrante_11, puesto:'Consultora Junior de Administración', mail: 'sbarrera@mafyasociados.com',redes_sociales: []},
    { index: 11, nombre:'Emilio Lira Pacheco', img_src: img_integrante_12, puesto:'Consultor Junior de Género y Derechos Humanos', mail: 'elira@mafyasociados.com',redes_sociales: []},
    { index: 12, nombre:'Lic. Ilse Liliana Hernández López', img_src: img_integrante_13, puesto:'Coordinadora de Género y Derechos Humanos', mail: 'ihernandez@mafyasociados.com',redes_sociales: []},
    { index: 13, nombre:'Lic. Samuel Zenteno Zamudio', img_src: img_integrante_14, puesto:'Consultor Senior de Asuntos Jurídicos y Legislativos', mail: 'szenteno@mafyasociados.com',redes_sociales: []},
    { index: 14, nombre:'Lic. Mario Antonio Ávila Varguez', img_src: img_integrante_15, puesto:'Consultor Senior de Asuntos Jurídicos y Legislativos', mail: 'mavila@mafyasociados.com',redes_sociales: []},
    { index: 15, nombre:'Lic. Stephanie Hernández Castelazo', img_src: img_integrante_16, puesto:'Consultora Junior de Asuntos Jurídicos', mail: 'shernandez@mafyasociados.com',redes_sociales: []},
    { index: 16, nombre:'MCS. Carlos E. Maya Rayas', img_src: img_integrante_17, puesto:'Coordinador de Asuntos Sociales y Políticos', mail: 'cmaya@mafyasociados.com',redes_sociales: []},
    { index: 17, nombre:'MPPM. José Gabriel Uribe Acevedo', img_src: img_integrante_18, puesto:'Consultor Senior de Asuntos Sociales y Políticos', mail: 'juribe@mafyasociados.com',redes_sociales: []},
    { index: 18, nombre:'Lic. Cesar Ramón Añorve Sánchez', img_src: img_integrante_19, puesto:'Consultor Senior de Asuntos Sociales y Políticos', mail: 'canorve@mafyasociados.com',redes_sociales: []},
    { index: 19, nombre:'Lic. Héctor Eduardo Vidal Díaz', img_src: img_integrante_20, puesto:'Consultor Junior de Asuntos Sociales y Políticos', mail: 'hvidal@mafyasociados.com',redes_sociales: []},
    { index: 20, nombre:'Carlos Elizarraráz Rodríguez', img_src: img_integrante_21, puesto:'Enlace Administrativo', mail: 'celizarraraz@mafyasociados.com',redes_sociales: []}
];


export const CarouselEquipo = (params) => {

    const { num_items } = params;

    const cambiarCarousel = React.createRef();


    let div_carosuel = [];
    let count_divs_carousel = Math.ceil(integrantes.length / num_items);

    for (let i = 0; i < count_divs_carousel; i++) {

        div_carosuel[i] =
            //filtrar
            integrantes.filter(integrante => {
                if (integrante.index >= (num_items * (i)) && integrante.index < (num_items * (i + 1))) {
                    return true;
                } else {
                    return false;
                }
            })
                //crear item
                .map(integrante => {

                    let links_redes_sociales = integrante.redes_sociales.map(red_social => {
                        return (
                            <a href={red_social.url} target="_blank">
                                <img src={red_social.url_icono} className="img_integrante_red_social" alt={red_social.nombre} />
                            </a>
                        );
                    });

                    return (
                        <Col xs={{span:24, offset:0}} sm={{span:12, offset:0}} md={{span:8, offset:0}} lg={{span:6, offset:0}}>
                            <Row>
                                <Col span={20} offset={2}>
                                    <img src={integrante.img_src} className="img_foto_integrante" alt={integrante.nombre} />
                                </Col>
                                <Col span={20} offset={2} className="contenedor_integrante">
                                    <Row>
                                        <Col span={24} offset={0}>
                                            <p className="nombre_integrante">{integrante.nombre}</p>
                                            <p className="puesto_integrante">{integrante.puesto}</p>

                                        </Col>
                                        <Col span={22} offset={1}>
                                            <Divider className="division_texto_integrante" />
                                        </Col>


                                        <Col span={16} offset={1} style={{ textAlign: 'left', marginTop:'0px', paddingTop:'0px' }}>
                                            {links_redes_sociales}
                                        </Col>
                                        <Col span={6} style={{ textAlign: 'right', paddingBottom:'10px' }}>
                                            <a href={`mailto:${integrante.mail}`} target="_blank">
                                                <span className="icono_mail_integrante">e-mail <RightOutlined /></span>
                                            </a>
                                        </Col>
                                        
                                    </Row>
                                </Col>


                            </Row>



                        </Col>
                    );
                });
        ;


        div_carosuel[i] = (
            <div>
                <Row>
                    {div_carosuel[i]}
                </Row>
            </div>
        );
    }

    const show_divs = div_carosuel.map(div => {
        return (
            div
        );
    }
    );


    return (
        <Row align="middle">
            <Col xs={{ span: 4, offset: 0 }} md={{ span: 2, offset: 0 }} style={{ textAlign: 'right' }}>
                <LeftOutlined className="flechas_cambiar_carousel" onClick={() => { cambiarCarousel.current.prev() }} />
            </Col>
            <Col xs={{ span: 16, offset: 0 }} md={{ span: 20, offset: 0 }}>
                <Carousel autoplay dots={false} ref={cambiarCarousel}>
                    {show_divs}
                </Carousel>
            </Col>
            <Col xs={{ span: 4, offset: 0 }} md={{ span: 2, offset: 0 }} style={{ textAlign: 'left' }}>
                <RightOutlined className="flechas_cambiar_carousel" onClick={() => { cambiarCarousel.current.next() }} />
            </Col>
        </Row>
    );

};